import React from "react";
import closeBtnStyle from "./closeBtnStyle";

const CloseBtn = (props) => {
    const s = closeBtnStyle(props.isDesktop)

    return <div style={s.btn} onClick={props.onClick}>
        <img style={s.icon}
             alt=""
             src="/assets/large-close.svg"/>
    </div>
}

export default CloseBtn