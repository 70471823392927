import React from 'react';
import './PosterProductPreview.css';
import posterProductStyle from "./posterProductStyle";

const PosterProductPreview = (props) => {
    const s = posterProductStyle(props.isDesktop)

    return (
    <div style={s.container}>
        <div style={s.title}>FREE WI-FI</div>
        <div style={s.qrContainer}
             className="poster-prdct-qr"
             dangerouslySetInnerHTML={{ __html: props.qrCodeSvgStr }}/>
        <div style={s.descriptionBlock}>
            <img style={s.descriptionBlock.pic}
                 alt=""
                 src="/assets/poster-tip.svg"/>
            <div style={s.descriptionBlock.text}>
                <div style={s.descriptionBlock.text.title}>Scan it</div>
                <div style={s.descriptionBlock.text.tip}>with camera or any<br/>QR scanner</div>
            </div>
        </div>
        <div style={s.credsContainer}>
            <div style={s.credsContainer.networkTitle}>NETWORK</div>
            <div style={s.credsContainer.val}>{props.network}</div>
            <div style={s.credsContainer.passwordTitle}>{ props.password && "PASSWORD" }</div>
            <div style={s.credsContainer.val}>{props.password}</div>
        </div>
        <div style={s.createdWith}><div style={s.createdWith.icon}/>waflee.app</div>
    </div>
    );
}

export default PosterProductPreview