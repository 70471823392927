import React from 'react'

const Emoji = (props) => {
    const size = '24px'

    const style = {
        display: 'inline-block',
        backgroundImage: 'url(/assets/emoji/' + props.type + '.png)',
        backgroundSize: size + ' ' + size,
        backgroundPosition: 'center center',
        width: size,
        height: size,
        verticalAlign: 'text-bottom',
        backgroundRepeat: 'no-repeat'
    }

    return <div style={{...style, ...props.style}}></div>
}

export default Emoji