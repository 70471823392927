export default function posterTemplate(network, password, qrCodeSvgStr) {
	const qrCodeFormatted = qrCodeSvgStr.replace(
		/width="\d+px"/g,
		''
	).replace(
		/height="\d+px"/g,
		'width="283px" height="283px" x="155px" y="118px"'
	);

	return `
<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 595.276 841.89" style="enable-background:new 0 0 595.276 841.89;" xml:space="preserve" transform="translate(-17 0)">`
+ qrCodeFormatted +
`<g id="logo_bottom" transform="translate(0 -50)">
	<path id="icon" style="fill:#333333;" d="M253.148,809.547c-0.645,0.533-0.67,1.523-0.081,2.113l9.639,9.639
		c0.564,0.564,1.478,0.564,2.042,0l9.639-9.639c0.589-0.589,0.564-1.579-0.081-2.113
		C268.185,804.463,259.267,804.463,253.148,809.547z M264.996,808.353c-0.01-0.01-0.025-0.025-0.03-0.041
		c1.026,0.081,2.057,0.269,3.057,0.569l-0.995,0.995c-0.142,0.142-0.366,0.142-0.508,0L264.996,808.353z M259.43,808.881
		c1-0.3,2.031-0.487,3.057-0.569c-0.005,0.015-0.02,0.031-0.03,0.041l-1.523,1.523c-0.142,0.142-0.366,0.142-0.508,0L259.43,808.881
		z M265.504,816.986c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523c-0.142,0.142-0.366,0.142-0.508,0l-1.523-1.523
		c-0.142-0.142-0.142-0.366,0-0.508l1.523-1.523c0.142-0.142,0.366-0.142,0.508,0L265.504,816.986z M262.457,813.939
		c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523c-0.142,0.142-0.366,0.142-0.508,0l-1.523-1.523c-0.142-0.142-0.142-0.366,0-0.508
		l1.523-1.523c0.142-0.142,0.366-0.142,0.508,0L262.457,813.939z M259.41,810.892c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523
		c-0.142,0.142-0.366,0.142-0.508,0l-1.523-1.523c-0.142-0.142-0.142-0.366,0-0.508l0.975-0.975c0.361-0.188,0.726-0.35,1.097-0.508
		L259.41,810.892z M268.551,813.939c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523c-0.142,0.142-0.366,0.142-0.508,0l-1.523-1.523
		c-0.142-0.142-0.142-0.366,0-0.508l1.523-1.523c0.142-0.142,0.366-0.142,0.508,0L268.551,813.939z M265.504,810.892
		c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523c-0.142,0.142-0.366,0.142-0.508,0l-1.523-1.523c-0.142-0.142-0.142-0.366,0-0.508
		l1.523-1.523c0.142-0.142,0.366-0.142,0.508,0L265.504,810.892z M271.598,810.892c0.142,0.142,0.142,0.366,0,0.508l-1.523,1.523
		c-0.142,0.142-0.366,0.142-0.508,0l-1.524-1.523c-0.142-0.142-0.142-0.366,0-0.508l1.483-1.483c0.371,0.157,0.736,0.32,1.097,0.508
		L271.598,810.892z"/>
	<g id="text_1_">
		<path style="fill:#333333;" d="M286.563,812.227c0.523,0.228,0.784,0.507,0.784,0.84c0,0.139-0.065,0.36-0.196,0.664
			c-0.131,0.304-0.241,0.571-0.331,0.798l-1.347,3.263c-0.123,0.358-0.326,0.608-0.613,0.755c-0.123,0.056-0.278,0.085-0.466,0.085
			c-0.188,0-0.369-0.071-0.545-0.213c-0.176-0.142-0.292-0.286-0.349-0.432l-0.098-0.207c-0.066-0.162-0.307-0.796-0.723-1.899
			c-0.082,0.194-0.202,0.513-0.361,0.955c-0.16,0.443-0.262,0.718-0.307,0.828s-0.073,0.182-0.086,0.219
			c-0.012,0.037-0.047,0.104-0.104,0.201c-0.057,0.097-0.122,0.182-0.196,0.256c-0.196,0.195-0.421,0.292-0.674,0.292
			c-0.253,0-0.465-0.071-0.637-0.213c-0.172-0.142-0.286-0.286-0.343-0.432l-1.85-4.481c-0.082-0.244-0.122-0.402-0.122-0.476
			c0-0.324,0.233-0.58,0.698-0.767c0.262-0.105,0.474-0.159,0.637-0.159c0.164,0,0.296,0.035,0.398,0.104
			c0.102,0.069,0.173,0.138,0.214,0.207c0.041,0.069,0.074,0.14,0.098,0.213c0.139,0.325,0.429,1.088,0.87,2.289l0.723-1.973
			c0.049-0.161,0.139-0.304,0.269-0.426c0.237-0.235,0.478-0.353,0.723-0.353c0.245,0,0.456,0.065,0.631,0.195
			c0.175,0.13,0.296,0.26,0.361,0.389l0.098,0.207c0.408,1.137,0.649,1.786,0.723,1.948l0.747-2.021
			c0.196-0.519,0.49-0.78,0.882-0.78C286.228,812.105,286.392,812.146,286.563,812.227z"/>
		<path style="fill:#333333;" d="M291.966,812.86c0.049-0.454,0.371-0.682,0.968-0.682c0.319,0,0.555,0.025,0.71,0.073
			c0.155,0.049,0.263,0.134,0.324,0.256c0.061,0.122,0.098,0.23,0.11,0.323c0.012,0.093,0.018,0.233,0.018,0.42v4.261
			c0,0.187-0.006,0.327-0.018,0.42c-0.012,0.094-0.047,0.201-0.104,0.323c-0.114,0.211-0.394,0.316-0.839,0.316
			c-0.445,0-0.743-0.055-0.894-0.164c-0.151-0.11-0.239-0.271-0.263-0.482c-0.303,0.43-0.807,0.645-1.513,0.645
			s-1.362-0.32-1.966-0.961c-0.605-0.641-0.907-1.386-0.907-2.234s0.305-1.591,0.913-2.228c0.609-0.637,1.276-0.955,2.003-0.955
			c0.278,0,0.531,0.049,0.76,0.146s0.388,0.188,0.478,0.273C291.835,812.695,291.908,812.779,291.966,812.86z M289.736,815.392
			c0,0.269,0.106,0.509,0.319,0.724c0.212,0.216,0.47,0.323,0.772,0.323s0.553-0.11,0.754-0.329c0.2-0.219,0.3-0.461,0.3-0.724
			s-0.096-0.509-0.288-0.736c-0.192-0.228-0.449-0.341-0.772-0.341s-0.584,0.113-0.784,0.341
			C289.836,814.877,289.736,815.125,289.736,815.392z"/>
		<path style="fill:#333333;" d="M298.85,812.191c0.564,0,0.894,0.154,0.992,0.462c0.049,0.155,0.074,0.358,0.074,0.609
			c0,0.252-0.027,0.453-0.08,0.602c-0.053,0.151-0.137,0.258-0.251,0.323c-0.171,0.097-0.421,0.146-0.747,0.146l-0.784-0.049v3.263
			c0,0.309-0.039,0.538-0.116,0.689c-0.078,0.15-0.196,0.249-0.355,0.298c-0.16,0.049-0.366,0.072-0.619,0.072
			c-0.253,0-0.456-0.026-0.607-0.079c-0.151-0.052-0.259-0.138-0.324-0.256c-0.065-0.117-0.104-0.225-0.117-0.322
			c-0.012-0.098-0.018-0.24-0.018-0.426v-3.227h-0.441c-0.212-0.016-0.363-0.064-0.453-0.146c-0.156-0.155-0.233-0.451-0.233-0.889
			c0-0.666,0.241-0.999,0.723-0.999h0.404v-0.645c0-0.625,0.224-1.191,0.673-1.698c0.449-0.507,1.099-0.761,1.948-0.761
			c0.188,0,0.328,0.006,0.423,0.018c0.094,0.013,0.202,0.051,0.324,0.116c0.22,0.106,0.331,0.418,0.331,0.937
			c0,0.389-0.07,0.67-0.208,0.84c-0.115,0.13-0.294,0.203-0.539,0.219c-0.073,0-0.188,0-0.343,0c-0.302,0-0.453,0.102-0.453,0.304
			v0.67L298.85,812.191z M300.553,810.23c0-0.186,0.006-0.327,0.018-0.42c0.012-0.093,0.051-0.201,0.117-0.322
			c0.106-0.22,0.42-0.329,0.943-0.329c0.498,0,0.809,0.109,0.931,0.329c0.066,0.121,0.105,0.231,0.117,0.328
			c0.013,0.098,0.019,0.24,0.019,0.426v5.638c0,0.292,0.024,0.477,0.073,0.554c0.05,0.077,0.158,0.116,0.325,0.116
			c0.167,0,0.28,0.006,0.337,0.018c0.057,0.012,0.131,0.047,0.22,0.103c0.156,0.106,0.233,0.394,0.233,0.865
			c0,0.511-0.078,0.823-0.233,0.937c-0.22,0.147-0.731,0.17-1.531,0.073c-0.629-0.081-1.041-0.288-1.237-0.621
			c-0.22-0.397-0.331-1.031-0.331-1.9V810.23z"/>
		<path style="fill:#333333;" d="M310.66,814.619c0,0.499-0.125,0.869-0.374,1.108c-0.249,0.24-0.524,0.36-0.826,0.36h-2.904
			c0,0.227,0.136,0.418,0.405,0.572c0.269,0.155,0.539,0.232,0.809,0.232c0.474,0,0.845-0.049,1.115-0.147l0.135-0.049
			c0.196-0.089,0.36-0.134,0.49-0.134c0.261,0,0.486,0.182,0.674,0.548c0.106,0.219,0.159,0.406,0.159,0.56
			c0,0.722-0.869,1.084-2.609,1.084c-0.604,0-1.141-0.104-1.611-0.311c-0.47-0.206-0.835-0.481-1.097-0.821
			c-0.514-0.658-0.772-1.392-0.772-2.204c0-1.023,0.333-1.841,0.999-2.453c0.665-0.613,1.496-0.92,2.492-0.92
			c1.135,0,1.968,0.398,2.499,1.193C310.52,813.659,310.66,814.12,310.66,814.619z M308.075,814.978c0.31,0,0.466-0.129,0.466-0.389
			c0-0.186-0.072-0.337-0.214-0.45c-0.143-0.114-0.347-0.17-0.612-0.17c-0.266,0-0.529,0.107-0.791,0.322
			c-0.261,0.215-0.392,0.445-0.392,0.688H308.075z"/>
		<path style="fill:#333333;" d="M317.679,814.619c0,0.499-0.125,0.869-0.374,1.108c-0.249,0.24-0.524,0.36-0.826,0.36h-2.904
			c0,0.227,0.136,0.418,0.405,0.572c0.269,0.155,0.539,0.232,0.809,0.232c0.474,0,0.845-0.049,1.115-0.147l0.135-0.049
			c0.196-0.089,0.36-0.134,0.49-0.134c0.261,0,0.486,0.182,0.674,0.548c0.106,0.219,0.159,0.406,0.159,0.56
			c0,0.722-0.869,1.084-2.609,1.084c-0.604,0-1.141-0.104-1.611-0.311c-0.47-0.206-0.835-0.481-1.097-0.821
			c-0.514-0.658-0.772-1.392-0.772-2.204c0-1.023,0.333-1.841,0.999-2.453c0.665-0.613,1.496-0.92,2.492-0.92
			c1.135,0,1.968,0.398,2.499,1.193C317.54,813.659,317.679,814.12,317.679,814.619z M315.094,814.978
			c0.31,0,0.466-0.129,0.466-0.389c0-0.186-0.072-0.337-0.214-0.45c-0.143-0.114-0.347-0.17-0.612-0.17
			c-0.266,0-0.529,0.107-0.791,0.322c-0.261,0.215-0.392,0.445-0.392,0.688H315.094z"/>
		<path style="fill:#333333;" d="M320.374,816.585c0.139,0.139,0.208,0.423,0.208,0.853v0.049c0,0.194-0.006,0.339-0.018,0.432
			c-0.013,0.093-0.056,0.205-0.129,0.335c-0.123,0.236-0.432,0.353-0.925,0.353c-0.494,0-0.82-0.076-0.98-0.231
			c-0.159-0.155-0.239-0.447-0.239-0.877v-0.049c0-0.186,0.006-0.329,0.019-0.426c0.012-0.097,0.051-0.207,0.116-0.329
			c0.123-0.244,0.431-0.366,0.925-0.366S320.186,816.415,320.374,816.585z"/>
		<path style="fill:#333333;" d="M325.568,812.86c0.049-0.454,0.372-0.682,0.968-0.682c0.318,0,0.555,0.025,0.71,0.073
			c0.156,0.049,0.264,0.134,0.325,0.256c0.062,0.122,0.098,0.23,0.111,0.323c0.012,0.093,0.018,0.233,0.018,0.42v4.261
			c0,0.187-0.006,0.327-0.018,0.42c-0.013,0.094-0.048,0.201-0.105,0.323c-0.115,0.211-0.394,0.316-0.838,0.316
			c-0.446,0-0.744-0.055-0.895-0.164c-0.151-0.11-0.238-0.271-0.263-0.482c-0.302,0.43-0.806,0.645-1.512,0.645
			c-0.707,0-1.362-0.32-1.966-0.961c-0.605-0.641-0.907-1.386-0.907-2.234s0.304-1.591,0.913-2.228
			c0.608-0.637,1.276-0.955,2.003-0.955c0.277,0,0.53,0.049,0.76,0.146c0.228,0.097,0.388,0.188,0.478,0.273
			S325.511,812.779,325.568,812.86z M323.339,815.392c0,0.269,0.106,0.509,0.318,0.724c0.212,0.216,0.47,0.323,0.772,0.323
			s0.553-0.11,0.754-0.329c0.2-0.219,0.3-0.461,0.3-0.724s-0.096-0.509-0.288-0.736c-0.191-0.228-0.449-0.341-0.772-0.341
			s-0.584,0.113-0.784,0.341C323.438,814.877,323.339,815.125,323.339,815.392z"/>
		<path style="fill:#333333;" d="M328.68,820.336v-7.111c0-0.186,0.006-0.325,0.018-0.414c0.012-0.089,0.047-0.19,0.104-0.304
			c0.115-0.219,0.388-0.329,0.821-0.329c0.433,0,0.725,0.055,0.875,0.164c0.152,0.11,0.239,0.271,0.263,0.481
			c0.311-0.421,0.799-0.632,1.464-0.632c0.665,0,1.296,0.312,1.892,0.937c0.596,0.625,0.894,1.352,0.894,2.179
			c0,0.828-0.296,1.555-0.887,2.18c-0.593,0.625-1.223,0.937-1.893,0.937s-1.155-0.186-1.458-0.56v2.459
			c0,0.186-0.006,0.324-0.019,0.414c-0.012,0.089-0.049,0.192-0.11,0.31c-0.062,0.118-0.165,0.201-0.313,0.25
			c-0.147,0.049-0.347,0.073-0.6,0.073c-0.253,0-0.455-0.025-0.606-0.073c-0.152-0.049-0.257-0.13-0.318-0.244
			c-0.062-0.114-0.099-0.215-0.111-0.304C328.686,820.661,328.68,820.523,328.68,820.336z M331.106,814.602
			c-0.188,0.219-0.282,0.458-0.282,0.718c0,0.26,0.096,0.496,0.288,0.711c0.191,0.216,0.437,0.323,0.735,0.323
			c0.298,0,0.551-0.105,0.76-0.316c0.208-0.211,0.312-0.451,0.312-0.718c0-0.268-0.098-0.509-0.294-0.725
			c-0.195-0.215-0.451-0.322-0.766-0.322C331.544,814.273,331.293,814.382,331.106,814.602z"/>
		<path style="fill:#333333;" d="M335.809,820.336v-7.111c0-0.186,0.006-0.325,0.018-0.414c0.012-0.089,0.047-0.19,0.104-0.304
			c0.115-0.219,0.388-0.329,0.821-0.329c0.433,0,0.725,0.055,0.875,0.164c0.152,0.11,0.239,0.271,0.263,0.481
			c0.311-0.421,0.799-0.632,1.464-0.632c0.665,0,1.296,0.312,1.892,0.937c0.596,0.625,0.894,1.352,0.894,2.179
			c0,0.828-0.296,1.555-0.887,2.18c-0.593,0.625-1.223,0.937-1.893,0.937c-0.67,0-1.155-0.186-1.457-0.56v2.459
			c0,0.186-0.006,0.324-0.019,0.414c-0.012,0.089-0.049,0.192-0.11,0.31c-0.062,0.118-0.165,0.201-0.313,0.25
			c-0.147,0.049-0.347,0.073-0.6,0.073c-0.253,0-0.455-0.025-0.606-0.073c-0.152-0.049-0.257-0.13-0.318-0.244
			c-0.062-0.114-0.099-0.215-0.111-0.304C335.815,820.661,335.809,820.523,335.809,820.336z M338.235,814.602
			c-0.188,0.219-0.282,0.458-0.282,0.718c0,0.26,0.096,0.496,0.288,0.711c0.191,0.216,0.437,0.323,0.735,0.323
			c0.298,0,0.551-0.105,0.76-0.316c0.208-0.211,0.312-0.451,0.312-0.718c0-0.268-0.098-0.509-0.294-0.725
			c-0.195-0.215-0.451-0.322-0.766-0.322C338.673,814.273,338.422,814.382,338.235,814.602z"/>
	</g>
</g>
<g id="title">
	<path style="fill:#333333;" d="M239.846,50.438c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.436,0.111,0.693,0.253
		c0.258,0.142,0.44,0.383,0.546,0.72c0.106,0.337,0.16,0.79,0.16,1.358c0,0.569-0.054,1.021-0.16,1.358
		c-0.106,0.338-0.293,0.573-0.559,0.707c-0.267,0.133-0.502,0.212-0.706,0.24c-0.205,0.026-0.52,0.04-0.946,0.04h-8.15v2.69h5.246
		c0.427,0,0.741,0.013,0.946,0.04c0.204,0.026,0.439,0.111,0.706,0.253c0.462,0.267,0.693,0.968,0.693,2.105
		c0,1.243-0.337,1.98-1.012,2.211c-0.284,0.089-0.737,0.132-1.358,0.132h-5.22v5.034c0,0.427-0.013,0.737-0.04,0.932
		c-0.026,0.196-0.111,0.427-0.253,0.693c-0.249,0.48-0.942,0.72-2.078,0.72c-1.243,0-1.971-0.337-2.185-1.012
		c-0.106-0.284-0.159-0.737-0.159-1.358v-14.81c0-0.905,0.169-1.522,0.506-1.851c0.337-0.328,0.976-0.492,1.917-0.492H239.846z"/>
	<path style="fill:#333333;" d="M258.837,66.658c0.267,0.638,0.4,1.101,0.4,1.385c0,0.674-0.551,1.243-1.652,1.704
		c-0.568,0.249-1.025,0.373-1.372,0.373c-0.346,0-0.63-0.083-0.852-0.253c-0.222-0.169-0.386-0.341-0.493-0.519
		c-0.178-0.337-0.87-1.944-2.078-4.822l-0.825,0.054h-3.356v3.01c0,0.409-0.013,0.714-0.04,0.919
		c-0.026,0.204-0.111,0.439-0.253,0.706c-0.249,0.479-0.942,0.719-2.078,0.719c-1.243,0-1.971-0.328-2.185-0.985
		c-0.106-0.301-0.159-0.763-0.159-1.385V52.781c0-0.407,0.013-0.714,0.04-0.918c0.026-0.205,0.111-0.44,0.253-0.707
		c0.249-0.479,0.942-0.719,2.078-0.719h5.754c1.562,0,3.08,0.568,4.554,1.705c0.71,0.551,1.296,1.296,1.758,2.237
		c0.462,0.942,0.693,1.989,0.693,3.143c0,2.007-0.665,3.658-1.997,4.954C257.416,63.418,258.02,64.812,258.837,66.658z
		 M248.61,59.867h3.41c0.515,0,1.03-0.195,1.544-0.586c0.515-0.39,0.773-0.976,0.773-1.758c0-0.781-0.258-1.372-0.773-1.771
		c-0.515-0.4-1.048-0.599-1.597-0.599h-3.356V59.867z"/>
	<path style="fill:#333333;" d="M265.416,65.246h8.203c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.44,0.111,0.707,0.253
		c0.479,0.249,0.719,0.942,0.719,2.078c0,1.243-0.328,1.971-0.985,2.185c-0.302,0.106-0.764,0.159-1.385,0.159h-10.547
		c-1.243,0-1.971-0.337-2.185-1.012c-0.106-0.284-0.159-0.737-0.159-1.358v-14.81c0-0.905,0.169-1.522,0.506-1.851
		c0.337-0.328,0.976-0.492,1.917-0.492h10.494c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.44,0.111,0.707,0.253
		c0.479,0.249,0.719,0.942,0.719,2.078c0,1.243-0.328,1.971-0.985,2.184c-0.302,0.107-0.764,0.16-1.385,0.16h-8.177v2.69h5.274
		c0.409,0,0.714,0.013,0.919,0.04c0.204,0.026,0.439,0.111,0.706,0.253c0.48,0.249,0.72,0.942,0.72,2.078
		c0,1.243-0.338,1.971-1.012,2.184c-0.302,0.107-0.764,0.16-1.385,0.16h-5.22V65.246z"/>
	<path style="fill:#333333;" d="M282.408,65.246h8.203c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.44,0.111,0.707,0.253
		c0.479,0.249,0.719,0.942,0.719,2.078c0,1.243-0.328,1.971-0.985,2.185c-0.302,0.106-0.764,0.159-1.385,0.159h-10.547
		c-1.243,0-1.971-0.337-2.185-1.012c-0.106-0.284-0.159-0.737-0.159-1.358v-14.81c0-0.905,0.169-1.522,0.506-1.851
		c0.337-0.328,0.976-0.492,1.917-0.492h10.494c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.44,0.111,0.707,0.253
		c0.479,0.249,0.719,0.942,0.719,2.078c0,1.243-0.328,1.971-0.985,2.184c-0.302,0.107-0.764,0.16-1.385,0.16h-8.177v2.69h5.274
		c0.409,0,0.714,0.013,0.919,0.04c0.204,0.026,0.439,0.111,0.706,0.253c0.48,0.249,0.72,0.942,0.72,2.078
		c0,1.243-0.338,1.971-1.012,2.184c-0.302,0.107-0.764,0.16-1.385,0.16h-5.22V65.246z"/>
	<path style="fill:#333333;" d="M324.783,50.544c1.19,0.426,1.785,0.995,1.785,1.705c0,0.231-0.107,0.665-0.32,1.305l-5.114,14.782
		c-0.036,0.125-0.098,0.275-0.186,0.453c-0.089,0.178-0.324,0.413-0.706,0.706c-0.383,0.293-0.835,0.439-1.358,0.439
		c-0.525,0-0.978-0.146-1.359-0.439c-0.382-0.293-0.635-0.582-0.759-0.866l-3.117-9.189c-1.97,5.825-2.974,8.79-3.01,8.897
		c-0.036,0.106-0.115,0.258-0.24,0.453s-0.267,0.373-0.426,0.532c-0.409,0.409-0.897,0.612-1.465,0.612
		c-0.568,0-1.034-0.132-1.398-0.399c-0.364-0.267-0.608-0.533-0.733-0.799l-0.186-0.4l-5.14-14.782
		c-0.196-0.585-0.293-1.021-0.293-1.305c0-0.71,0.594-1.269,1.784-1.678c0.515-0.178,0.981-0.267,1.398-0.267
		c0.417,0,0.737,0.142,0.959,0.426c0.222,0.284,0.422,0.72,0.599,1.305l2.93,8.897l2.902-8.763c0.249-0.746,0.701-1.26,1.359-1.544
		c0.284-0.125,0.652-0.186,1.105-0.186c0.452,0,0.874,0.146,1.265,0.439c0.39,0.294,0.639,0.582,0.746,0.866l3.063,9.242l2.93-8.95
		c0.142-0.407,0.257-0.71,0.346-0.905s0.254-0.387,0.493-0.573c0.24-0.186,0.536-0.28,0.892-0.28
		C323.886,50.277,324.304,50.367,324.783,50.544z"/>
	<path style="fill:#333333;" d="M328.032,52.781c0-0.407,0.013-0.714,0.04-0.918c0.026-0.205,0.111-0.44,0.253-0.707
		c0.249-0.479,0.942-0.719,2.078-0.719c1.243,0,1.98,0.328,2.211,0.985c0.089,0.302,0.133,0.764,0.133,1.385v14.808
		c0,0.427-0.013,0.737-0.04,0.932c-0.026,0.196-0.111,0.427-0.253,0.693c-0.249,0.48-0.942,0.72-2.078,0.72
		c-1.243,0-1.971-0.337-2.185-1.012c-0.106-0.284-0.159-0.737-0.159-1.358V52.781z"/>
	<path style="fill:#333333;" d="M342.734,66.259h-6.339c-0.995,0-1.59-0.301-1.785-0.906c-0.089-0.32-0.133-0.727-0.133-1.225
		c0-0.497,0.054-0.896,0.16-1.199c0.106-0.301,0.275-0.515,0.506-0.639c0.32-0.159,0.746-0.24,1.278-0.24h6.34
		c0.994,0,1.58,0.293,1.758,0.879c0.106,0.32,0.159,0.728,0.159,1.225c0,0.497-0.049,0.902-0.146,1.213
		c-0.098,0.31-0.254,0.528-0.466,0.652C343.746,66.178,343.302,66.259,342.734,66.259z"/>
	<path style="fill:#333333;" d="M359.326,50.438c0.409,0,0.715,0.013,0.919,0.04c0.204,0.026,0.436,0.111,0.693,0.253
		c0.258,0.142,0.44,0.383,0.546,0.72c0.106,0.337,0.16,0.79,0.16,1.358c0,0.569-0.054,1.021-0.16,1.358
		c-0.106,0.338-0.293,0.573-0.559,0.707s-0.502,0.212-0.706,0.24c-0.205,0.026-0.52,0.04-0.946,0.04h-8.15v2.69h5.246
		c0.427,0,0.741,0.013,0.946,0.04c0.204,0.026,0.439,0.111,0.706,0.253c0.462,0.267,0.693,0.968,0.693,2.105
		c0,1.243-0.337,1.98-1.012,2.211c-0.284,0.089-0.737,0.132-1.358,0.132h-5.22v5.034c0,0.427-0.013,0.737-0.04,0.932
		c-0.026,0.196-0.111,0.427-0.253,0.693c-0.249,0.48-0.942,0.72-2.078,0.72c-1.243,0-1.971-0.337-2.185-1.012
		c-0.106-0.284-0.159-0.737-0.159-1.358v-14.81c0-0.905,0.169-1.522,0.506-1.851c0.337-0.328,0.976-0.492,1.917-0.492H359.326z"/>
	<path style="fill:#333333;" d="M363.375,52.781c0-0.407,0.013-0.714,0.04-0.918c0.026-0.205,0.111-0.44,0.253-0.707
		c0.249-0.479,0.942-0.719,2.078-0.719c1.243,0,1.98,0.328,2.211,0.985c0.089,0.302,0.133,0.764,0.133,1.385v14.808
		c0,0.427-0.013,0.737-0.04,0.932c-0.026,0.196-0.111,0.427-0.253,0.693c-0.249,0.48-0.942,0.72-2.078,0.72
		c-1.243,0-1.971-0.337-2.185-1.012c-0.106-0.284-0.159-0.737-0.159-1.358V52.781z"/>
</g>
<g id="qr">
<g>
	<g transform="translate(0 -15)">
		<path style="fill:#333333;" d="M270.849,605.256v-8.16h2.796l1.968,6.72h0.12v-6.72h1.656v8.16h-2.724l-2.04-6.72h-0.12v6.72
			H270.849z"/>
		<path style="fill:#333333;" d="M279.165,605.256v-8.16h5.28v1.44h-3.624v1.908h2.904v1.415h-2.904v1.956h3.624v1.44H279.165z"/>
		<path style="fill:#333333;" d="M285.081,598.56v-1.464h6v1.464h-2.16v6.696h-1.656v-6.696H285.081z"/>
		<path style="fill:#333333;" d="M291.464,597.096h1.74l1.008,6.744h0.18l1.464-6.744h1.92l1.464,6.744h0.18l1.008-6.744h1.741
			l-1.561,8.16H298.1l-1.284-6.204l-1.284,6.204h-2.508L291.464,597.096z"/>
		<path style="fill:#333333;" d="M308.931,604.386c-0.508,0.676-1.382,1.014-2.622,1.014s-2.114-0.338-2.622-1.014
			s-0.762-1.733-0.762-3.174s0.254-2.51,0.762-3.21s1.382-1.05,2.622-1.05s2.114,0.35,2.622,1.05s0.762,1.77,0.762,3.21
			S309.438,603.711,308.931,604.386z M304.982,603.331c0.236,0.42,0.679,0.63,1.326,0.63c0.648,0,1.09-0.21,1.326-0.63
			c0.235-0.42,0.354-1.124,0.354-2.112s-0.119-1.706-0.359-2.154c-0.24-0.447-0.681-0.672-1.32-0.672s-1.08,0.225-1.32,0.672
			c-0.239,0.448-0.359,1.166-0.359,2.154S304.747,602.911,304.982,603.331z"/>
		<path style="fill:#333333;" d="M312.765,602.52v2.736h-1.656v-8.16h3.168c1.92,0,2.879,0.896,2.879,2.688
			c0,1.063-0.398,1.848-1.199,2.352l1.164,3.12h-1.812l-0.947-2.736H312.765z M315.177,600.745c0.191-0.24,0.287-0.557,0.287-0.948
			s-0.102-0.704-0.305-0.937c-0.205-0.231-0.498-0.348-0.883-0.348h-1.512v2.592h1.535
			C314.692,601.104,314.983,600.984,315.177,600.745z"/>
		<path style="fill:#333333;" d="M320.204,605.256h-1.656v-8.16h1.656v3.588l1.104-0.12l1.344-3.468h1.884l-1.776,4.092l1.824,4.068
			h-1.908l-1.367-3.252l-1.104,0.12V605.256z"/>
	</g>
</g>
<g id="tip">
	<g id="illustration">
		<path style="fill:#F4F5F6;" d="M428.031,537.244H167.244c-6.262,0-11.339-5.076-11.339-11.339v-82.205
			c0-6.262,5.076-11.339,11.339-11.339h260.787c6.262,0,11.339,5.076,11.339,11.339v82.205
			C439.37,532.168,434.294,537.244,428.031,537.244z"/>
		<g>
			<g>
				<g>
					<path style="fill:#C7CED1;" d="M203.827,512.37h-4.087c-1.657,0-3-1.343-3-3v-56.008c0-1.657,1.343-3,3-3h4.087V512.37z"/>
				</g>
				<g>
					<path style="fill:#FFFFFF;" d="M233.059,453.02v56.693c0,1.47-1.187,2.657-2.657,2.657h-30.118
						c-1.47,0-2.657-1.187-2.657-2.657V453.02c0-1.47,1.187-2.657,2.657-2.657h30.118
						C231.872,450.362,233.059,451.549,233.059,453.02z"/>
				</g>
				<g>
					<rect x="210.913" y="469.85" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M210.028,472.508h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.657
						c0-0.489-0.397-0.886-0.886-0.886h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.657
						C209.142,472.111,209.538,472.508,210.028,472.508z M210.028,468.965h2.657v2.657h-2.657V468.965z"/>
					<rect x="210.913" y="479.594" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M212.685,477.823h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.658
						c0,0.489,0.397,0.886,0.886,0.886h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.658
						C213.571,478.219,213.174,477.823,212.685,477.823z M212.685,481.366h-2.657v-2.658h2.657V481.366z"/>
					<rect x="220.657" y="469.85" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M222.429,468.079h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.657
						c0,0.489,0.397,0.886,0.886,0.886h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.657
						C223.315,468.475,222.918,468.079,222.429,468.079z M222.429,471.622h-2.657v-2.657h2.657V471.622z"/>
					<rect x="216.228" y="468.079" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="217.114,472.508 217.114,470.736 216.228,470.736 216.228,471.622 214.457,471.622 
						214.457,472.508 216.228,472.508 					"/>
					<rect x="209.142" y="473.394" style="fill:#484848;" width="1.772" height="0.886"/>
					<rect x="211.799" y="473.394" style="fill:#484848;" width="0.886" height="0.886"/>
					<polygon style="fill:#484848;" points="210.913,476.051 212.685,476.051 212.685,476.937 213.571,476.937 213.571,476.051 
						213.571,475.165 210.913,475.165 					"/>
					<rect x="210.028" y="476.051" style="fill:#484848;" width="0.886" height="0.886"/>
					<polygon style="fill:#484848;" points="214.457,474.28 218,474.28 218,473.394 214.457,473.394 213.571,473.394 
						213.571,475.165 214.457,475.165 					"/>
					<rect x="214.457" y="476.051" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="218,476.937 216.228,476.937 216.228,477.823 218.886,477.823 218.886,476.937 
						218.886,476.051 218,476.051 					"/>
					<rect x="220.657" y="473.394" style="fill:#484848;" width="1.772" height="0.886"/>
					<polygon style="fill:#484848;" points="221.543,476.937 222.429,476.937 222.429,476.051 221.543,476.051 221.543,475.165 
						220.657,475.165 220.657,476.937 219.772,476.937 219.772,478.709 220.657,478.709 220.657,477.823 221.543,477.823 					"/>
					<polygon style="fill:#484848;" points="221.543,478.709 221.543,482.252 222.429,482.252 222.429,479.594 223.315,479.594 
						223.315,478.709 222.429,478.709 					"/>
					<rect x="218.886" y="473.394" style="fill:#484848;" width="0.886" height="2.657"/>
					<rect x="216.228" y="478.709" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="218,480.48 219.772,480.48 219.772,481.366 220.657,481.366 220.657,480.48 
						220.657,479.594 218,479.594 					"/>
					<rect x="214.457" y="478.709" style="fill:#484848;" width="0.886" height="2.657"/>
					<rect x="216.228" y="481.366" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="216.228" y="475.165" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="214.457" y="469.85" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="214.457" y="468.079" style="fill:#484848;" width="0.886" height="0.886"/>
				</g>
				<path style="fill:#ACABAB;" d="M221.929,491.996h-11.402c-0.276,0-0.5-0.224-0.5-0.5v-0.772c0-0.276,0.224-0.5,0.5-0.5h11.402
					c0.276,0,0.5,0.224,0.5,0.5v0.772C222.429,491.772,222.205,491.996,221.929,491.996z"/>
				<path style="fill:#ACABAB;" d="M220.157,495.539h-7.858c-0.276,0-0.5-0.224-0.5-0.5v-0.772c0-0.276,0.224-0.5,0.5-0.5h7.858
					c0.276,0,0.5,0.224,0.5,0.5v0.772C220.657,495.316,220.434,495.539,220.157,495.539z"/>
			</g>
			<g>
				<g>
					<path style="fill:#5193CF;" d="M273.807,461.878v56.693c0,1.468-1.19,2.657-2.658,2.657h-25.689
						c-1.957,0-3.543-1.586-3.543-3.543v-54.921c0-1.957,1.586-3.543,3.543-3.543h25.689
						C272.617,459.22,273.807,460.41,273.807,461.878z"/>
				</g>
				<path style="fill:#555554;" d="M275.579,461.878v56.693c0,1.47-1.187,2.657-2.657,2.657h-26.575
					c-1.47,0-2.657-1.187-2.657-2.657v-56.693c0-1.471,1.187-2.658,2.657-2.658h26.575
					C274.392,459.22,275.579,460.407,275.579,461.878z"/>
				<g>
					<path style="fill:#EEEFEF;" d="M274.693,461.878v56.693c0,0.974-0.797,1.772-1.772,1.772h-26.575
						c-0.974,0-1.772-0.797-1.772-1.772v-56.693c0-0.974,0.797-1.772,1.772-1.772h7.972v1.772c0,0.487,0.399,0.886,0.886,0.886
						h10.63c0.487,0,0.886-0.399,0.886-0.886v-1.772h6.201C273.896,460.106,274.693,460.904,274.693,461.878z"/>
				</g>
				<path style="fill:#9E9E9E;" d="M272.693,520.343h-26.118c-1.105,0-2-0.895-2-2v-8.63h30.118v8.63
					C274.693,519.447,273.797,520.343,272.693,520.343z"/>
				<path style="fill:#FFFFFF;" d="M264.649,519.457h-8.258c-0.166,0-0.3-0.134-0.3-0.3v-0.286c0-0.166,0.134-0.3,0.3-0.3h8.258
					c0.166,0,0.3,0.134,0.3,0.3v0.286C264.949,519.322,264.815,519.457,264.649,519.457z"/>
				<g>
					<circle style="fill:#FFFFFF;" cx="260.52" cy="514.142" r="1.772"/>
				</g>
				<g>
					<rect x="255.205" y="476.051" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M254.319,478.709h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.658
						c0-0.489-0.397-0.886-0.886-0.886h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.658
						C253.433,478.312,253.83,478.709,254.319,478.709z M254.319,475.165h2.657v2.658h-2.657V475.165z"/>
					<rect x="255.205" y="485.795" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M256.976,484.024h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.657
						c0,0.489,0.397,0.886,0.886,0.886h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.657
						C257.862,484.42,257.466,484.024,256.976,484.024z M256.976,487.567h-2.657v-2.657h2.657V487.567z"/>
					<rect x="264.949" y="476.051" style="fill:#484848;" width="0.886" height="0.886"/>
					<path style="fill:#484848;" d="M266.72,474.28h-2.657c-0.489,0-0.886,0.397-0.886,0.886v2.658c0,0.489,0.397,0.886,0.886,0.886
						h2.657c0.489,0,0.886-0.397,0.886-0.886v-2.658C267.606,474.676,267.21,474.28,266.72,474.28z M266.72,477.823h-2.657v-2.658
						h2.657V477.823z"/>
					<rect x="260.52" y="474.28" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="261.406,478.709 261.406,476.937 260.52,476.937 260.52,477.823 258.748,477.823 
						258.748,478.709 260.52,478.709 					"/>
					<rect x="253.433" y="479.594" style="fill:#484848;" width="1.772" height="0.886"/>
					<rect x="256.091" y="479.594" style="fill:#484848;" width="0.886" height="0.886"/>
					<polygon style="fill:#484848;" points="255.205,482.252 256.976,482.252 256.976,483.138 257.862,483.138 257.862,482.252 
						257.862,481.366 255.205,481.366 					"/>
					<rect x="254.319" y="482.252" style="fill:#484848;" width="0.886" height="0.886"/>
					<polygon style="fill:#484848;" points="258.748,480.48 262.291,480.48 262.291,479.594 258.748,479.594 257.862,479.594 
						257.862,481.366 258.748,481.366 					"/>
					<rect x="258.748" y="482.252" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="262.291,483.138 260.52,483.138 260.52,484.024 263.177,484.024 263.177,483.138 
						263.177,482.252 262.291,482.252 					"/>
					<rect x="264.949" y="479.594" style="fill:#484848;" width="1.772" height="0.886"/>
					<polygon style="fill:#484848;" points="265.835,483.138 266.72,483.138 266.72,482.252 265.835,482.252 265.835,481.366 
						264.949,481.366 264.949,483.138 264.063,483.138 264.063,484.909 264.949,484.909 264.949,484.024 265.835,484.024 					"/>
					<polygon style="fill:#484848;" points="265.835,484.909 265.835,488.453 266.72,488.453 266.72,485.795 267.606,485.795 
						267.606,484.909 266.72,484.909 					"/>
					<rect x="263.177" y="479.594" style="fill:#484848;" width="0.886" height="2.657"/>
					<rect x="260.52" y="484.909" style="fill:#484848;" width="0.886" height="1.772"/>
					<polygon style="fill:#484848;" points="262.291,486.681 264.063,486.681 264.063,487.567 264.949,487.567 264.949,486.681 
						264.949,485.795 262.291,485.795 					"/>
					<rect x="258.748" y="484.909" style="fill:#484848;" width="0.886" height="2.657"/>
					<rect x="260.52" y="487.567" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="260.52" y="481.366" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="258.748" y="476.051" style="fill:#484848;" width="0.886" height="0.886"/>
					<rect x="258.748" y="474.28" style="fill:#484848;" width="0.886" height="0.886"/>
				</g>
				<polygon style="fill:#F6B519;" points="254.319,471.622 250.776,471.622 250.776,475.165 251.661,475.165 251.661,472.508 
					254.319,472.508 				"/>
				<polygon style="fill:#F6B519;" points="270.264,475.165 270.264,471.622 266.72,471.622 266.72,472.508 269.378,472.508 
					269.378,475.165 				"/>
				<polygon style="fill:#F6B519;" points="266.72,491.11 270.264,491.11 270.264,487.567 269.378,487.567 269.378,490.224 
					266.72,490.224 				"/>
				<polygon style="fill:#F6B519;" points="250.776,487.567 250.776,491.11 254.319,491.11 254.319,490.224 251.661,490.224 
					251.661,487.567 				"/>
				<path style="fill:#ACABAB;" d="M266.22,498.197h-11.402c-0.276,0-0.5-0.224-0.5-0.5v-0.772c0-0.276,0.224-0.5,0.5-0.5h11.402
					c0.276,0,0.5,0.224,0.5,0.5v0.772C266.72,497.973,266.497,498.197,266.22,498.197z"/>
				<path style="fill:#ACABAB;" d="M264.449,501.74h-7.858c-0.276,0-0.5-0.224-0.5-0.5v-0.772c0-0.276,0.224-0.5,0.5-0.5h7.858
					c0.276,0,0.5,0.224,0.5,0.5v0.772C264.949,501.516,264.725,501.74,264.449,501.74z"/>
			</g>
		</g>
	</g>
	<g id="text_1">
		<path style="fill:#333333;" d="M307.452,461.963c-1.018,0-1.526,0.336-1.526,1.008c0,0.299,0.126,0.527,0.379,0.686
			c0.252,0.159,0.842,0.388,1.771,0.687s1.58,0.637,1.953,1.015s0.56,0.959,0.56,1.743c0,0.999-0.303,1.755-0.909,2.269
			c-0.607,0.513-1.4,0.77-2.381,0.77c-0.728,0-1.619-0.112-2.674-0.336l-0.504-0.098l0.196-1.498c1.25,0.168,2.207,0.252,2.87,0.252
			c0.989,0,1.483-0.411,1.483-1.232c0-0.299-0.114-0.532-0.343-0.7s-0.684-0.35-1.365-0.546c-1.082-0.308-1.846-0.674-2.289-1.099
			s-0.665-1.015-0.665-1.771c0-0.951,0.29-1.661,0.868-2.128c0.579-0.467,1.372-0.7,2.38-0.7c0.691,0,1.568,0.094,2.633,0.28
			l0.504,0.099l-0.154,1.525C308.921,462.037,307.993,461.963,307.452,461.963z"/>
		<path style="fill:#333333;" d="M314.592,462.803c0.484,0,1.105,0.075,1.861,0.224l0.378,0.084l-0.056,1.484
			c-0.737-0.074-1.283-0.112-1.638-0.112c-0.645,0-1.071,0.138-1.281,0.413s-0.315,0.796-0.315,1.562
			c0,0.765,0.105,1.29,0.315,1.574c0.21,0.285,0.642,0.428,1.295,0.428l1.624-0.112l0.056,1.498
			c-0.998,0.196-1.759,0.294-2.281,0.294c-1.036,0-1.78-0.292-2.233-0.875s-0.679-1.519-0.679-2.807s0.233-2.22,0.7-2.793
			C312.804,463.09,313.556,462.803,314.592,462.803z"/>
		<path style="fill:#333333;" d="M323.58,465.154v2.912c0.019,0.225,0.056,0.381,0.111,0.47c0.057,0.089,0.173,0.151,0.351,0.188
			l-0.057,1.414c-0.476,0-0.856-0.033-1.141-0.098c-0.285-0.065-0.572-0.196-0.861-0.393c-0.672,0.327-1.357,0.49-2.058,0.49
			c-1.447,0-2.17-0.766-2.17-2.296c0-0.747,0.2-1.276,0.602-1.589s1.018-0.497,1.849-0.553l1.497-0.112v-0.435
			c0-0.289-0.064-0.487-0.195-0.595s-0.341-0.161-0.63-0.161l-2.632,0.112l-0.057-1.302c0.999-0.271,1.948-0.406,2.85-0.406
			c0.9,0,1.549,0.187,1.945,0.56C323.38,463.736,323.58,464.334,323.58,465.154z M320.429,467.003
			c-0.522,0.047-0.784,0.326-0.784,0.84s0.229,0.77,0.687,0.77c0.354,0,0.741-0.056,1.161-0.168l0.21-0.056v-1.498L320.429,467.003z
			"/>
		<path style="fill:#333333;" d="M327.248,469.971h-1.876v-7h1.862v0.392c0.634-0.373,1.218-0.56,1.75-0.56
			c0.933,0,1.577,0.275,1.932,0.826s0.532,1.399,0.532,2.548v3.794h-1.876v-3.738c0-0.597-0.07-1.038-0.21-1.322
			c-0.141-0.285-0.42-0.428-0.841-0.428c-0.383,0-0.751,0.065-1.105,0.196l-0.168,0.056V469.971z"/>
		<path style="fill:#333333;" d="M336.193,462.074v-1.903h1.876v1.903H336.193z M336.193,469.971v-7h1.876v7H336.193z"/>
		<path style="fill:#333333;" d="M343.683,464.566h-1.722v2.87c0,0.28,0.007,0.479,0.021,0.595c0.014,0.117,0.065,0.218,0.154,0.302
			c0.088,0.084,0.226,0.126,0.413,0.126l1.063-0.028l0.084,1.498c-0.625,0.14-1.102,0.21-1.428,0.21
			c-0.84,0-1.414-0.189-1.722-0.567c-0.309-0.378-0.462-1.075-0.462-2.093v-2.912h-0.826v-1.596h0.826v-1.946h1.876v1.946h1.722
			V464.566z"/>
	</g>
	<g id="text_2">
		<path style="fill:#787878;" d="M303.862,482.565h0.9l1.212,5.22h0.191l1.428-5.1h0.937l1.428,5.1h0.204l1.2-5.22h0.9l-1.429,6
			h-1.5l-1.271-4.692l-1.272,4.692h-1.5L303.862,482.565z"/>
		<path style="fill:#787878;" d="M313.498,481.209v-1.044h0.899v1.044H313.498z M313.498,488.565v-6h0.899v6H313.498z"/>
		<path style="fill:#787878;" d="M319.233,483.346h-1.907v2.868c0,0.688,0.05,1.14,0.149,1.355s0.338,0.324,0.714,0.324l1.068-0.072
			l0.06,0.744c-0.536,0.088-0.943,0.132-1.224,0.132c-0.624,0-1.056-0.151-1.296-0.456c-0.24-0.304-0.36-0.884-0.36-1.74v-3.155
			h-0.852v-0.78h0.852v-1.836h0.889v1.836h1.907V483.346z"/>
		<path style="fill:#787878;" d="M321.237,488.565h-0.899v-8.616h0.899v2.952c0.641-0.304,1.256-0.456,1.849-0.456
			c0.8,0,1.338,0.216,1.613,0.648c0.276,0.432,0.414,1.199,0.414,2.304v3.168h-0.899v-3.144c0-0.832-0.082-1.402-0.246-1.71
			c-0.164-0.309-0.51-0.463-1.038-0.463c-0.504,0-0.992,0.093-1.464,0.276l-0.229,0.084V488.565z"/>
		<path style="fill:#787878;" d="M331.666,482.445c0.264,0,0.712,0.048,1.344,0.145l0.288,0.035l-0.036,0.732
			c-0.641-0.072-1.112-0.108-1.416-0.108c-0.68,0-1.143,0.162-1.386,0.486c-0.244,0.324-0.366,0.924-0.366,1.8
			s0.114,1.486,0.342,1.83c0.229,0.345,0.702,0.517,1.422,0.517l1.416-0.108l0.036,0.744c-0.744,0.111-1.3,0.168-1.668,0.168
			c-0.936,0-1.582-0.24-1.938-0.72c-0.356-0.48-0.534-1.29-0.534-2.431c0-1.14,0.191-1.939,0.576-2.399
			C330.129,482.676,330.769,482.445,331.666,482.445z"/>
		<path style="fill:#787878;" d="M338.889,484.377v3.072c0.024,0.296,0.256,0.473,0.696,0.528l-0.036,0.708
			c-0.632,0-1.107-0.16-1.428-0.48c-0.72,0.32-1.44,0.48-2.16,0.48c-0.552,0-0.972-0.156-1.26-0.468
			c-0.288-0.312-0.432-0.761-0.432-1.345c0-0.583,0.147-1.014,0.443-1.29c0.296-0.275,0.76-0.445,1.393-0.51l1.884-0.18v-0.517
			c0-0.407-0.088-0.699-0.265-0.876c-0.176-0.176-0.416-0.264-0.72-0.264c-0.64,0-1.304,0.04-1.992,0.12l-0.372,0.036l-0.035-0.685
			c0.88-0.176,1.659-0.264,2.34-0.264c0.68,0,1.174,0.156,1.481,0.468C338.736,483.225,338.889,483.714,338.889,484.377z
			 M335.194,486.825c0,0.736,0.304,1.104,0.912,1.104c0.544,0,1.08-0.092,1.607-0.276l0.276-0.096v-1.98l-1.776,0.168
			c-0.359,0.032-0.62,0.137-0.78,0.312S335.194,486.489,335.194,486.825z"/>
		<path style="fill:#787878;" d="M341.565,488.565h-0.899v-6h0.888v0.42c0.6-0.36,1.184-0.54,1.752-0.54
			c0.744,0,1.264,0.204,1.561,0.612c0.264-0.16,0.605-0.302,1.025-0.426s0.79-0.187,1.11-0.187c0.8,0,1.338,0.214,1.614,0.643
			c0.275,0.428,0.413,1.197,0.413,2.31v3.168h-0.899v-3.144c0-0.832-0.08-1.402-0.24-1.71c-0.16-0.309-0.5-0.463-1.02-0.463
			c-0.265,0-0.536,0.038-0.816,0.114s-0.496,0.15-0.648,0.222l-0.228,0.108c0.104,0.264,0.156,0.848,0.156,1.752v3.12h-0.9v-3.096
			c0-0.864-0.078-1.45-0.233-1.758c-0.156-0.309-0.498-0.463-1.026-0.463c-0.256,0-0.519,0.038-0.786,0.114s-0.474,0.15-0.618,0.222
			l-0.204,0.108V488.565z"/>
		<path style="fill:#787878;" d="M354.778,487.821l0.348-0.036l0.024,0.708c-0.912,0.128-1.692,0.192-2.341,0.192
			c-0.863,0-1.476-0.25-1.836-0.75c-0.359-0.5-0.54-1.278-0.54-2.334c0-2.104,0.836-3.156,2.509-3.156
			c0.808,0,1.411,0.227,1.812,0.678c0.4,0.452,0.6,1.162,0.6,2.131l-0.048,0.684h-3.96c0,0.664,0.12,1.156,0.36,1.476
			c0.24,0.32,0.658,0.48,1.254,0.48S354.162,487.869,354.778,487.821z M354.454,485.218c0-0.736-0.118-1.256-0.354-1.561
			c-0.235-0.304-0.62-0.456-1.151-0.456c-0.532,0-0.933,0.16-1.2,0.48c-0.269,0.32-0.406,0.832-0.414,1.536H354.454z"/>
		<path style="fill:#787878;" d="M356.757,488.565v-6h0.888v0.816c0.696-0.48,1.428-0.796,2.196-0.948v0.912
			c-0.336,0.063-0.687,0.164-1.05,0.3c-0.364,0.136-0.643,0.256-0.834,0.36l-0.301,0.155v4.404H356.757z"/>
		<path style="fill:#787878;" d="M365.144,484.377v3.072c0.024,0.296,0.256,0.473,0.696,0.528l-0.036,0.708
			c-0.632,0-1.107-0.16-1.428-0.48c-0.72,0.32-1.44,0.48-2.16,0.48c-0.552,0-0.972-0.156-1.26-0.468
			c-0.288-0.312-0.432-0.761-0.432-1.345c0-0.583,0.147-1.014,0.443-1.29c0.296-0.275,0.76-0.445,1.393-0.51l1.884-0.18v-0.517
			c0-0.407-0.088-0.699-0.265-0.876c-0.176-0.176-0.416-0.264-0.72-0.264c-0.64,0-1.304,0.04-1.992,0.12l-0.372,0.036l-0.035-0.685
			c0.88-0.176,1.659-0.264,2.34-0.264c0.68,0,1.174,0.156,1.481,0.468C364.991,483.225,365.144,483.714,365.144,484.377z
			 M361.449,486.825c0,0.736,0.304,1.104,0.912,1.104c0.544,0,1.08-0.092,1.607-0.276l0.276-0.096v-1.98l-1.776,0.168
			c-0.359,0.032-0.62,0.137-0.78,0.312S361.449,486.489,361.449,486.825z"/>
		<path style="fill:#787878;" d="M369.296,485.554c0-1.097,0.196-1.889,0.589-2.376c0.392-0.488,1.054-0.732,1.985-0.732
			s1.592,0.244,1.98,0.732c0.388,0.487,0.582,1.279,0.582,2.376c0,1.096-0.185,1.892-0.553,2.388
			c-0.367,0.496-1.04,0.744-2.016,0.744c-0.977,0-1.648-0.248-2.016-0.744C369.481,487.445,369.296,486.649,369.296,485.554z
			 M370.221,485.535c0,0.876,0.105,1.49,0.317,1.842c0.212,0.353,0.656,0.528,1.332,0.528s1.118-0.174,1.326-0.521
			c0.208-0.349,0.312-0.962,0.312-1.843c0-0.88-0.115-1.485-0.348-1.817s-0.662-0.498-1.29-0.498s-1.06,0.166-1.296,0.498
			S370.221,484.659,370.221,485.535z"/>
		<path style="fill:#787878;" d="M375.897,488.565v-6h0.888v0.816c0.696-0.48,1.428-0.796,2.196-0.948v0.912
			c-0.336,0.063-0.687,0.164-1.05,0.3c-0.364,0.136-0.643,0.256-0.834,0.36l-0.301,0.155v4.404H375.897z"/>
		<path style="fill:#787878;" d="M386.924,484.377v3.072c0.024,0.296,0.256,0.473,0.696,0.528l-0.036,0.708
			c-0.632,0-1.107-0.16-1.428-0.48c-0.72,0.32-1.44,0.48-2.16,0.48c-0.552,0-0.972-0.156-1.26-0.468
			c-0.288-0.312-0.432-0.761-0.432-1.345c0-0.583,0.147-1.014,0.443-1.29c0.296-0.275,0.76-0.445,1.393-0.51l1.884-0.18v-0.517
			c0-0.407-0.088-0.699-0.265-0.876c-0.176-0.176-0.416-0.264-0.72-0.264c-0.64,0-1.304,0.04-1.992,0.12l-0.372,0.036l-0.035-0.685
			c0.88-0.176,1.659-0.264,2.34-0.264c0.68,0,1.174,0.156,1.481,0.468C386.771,483.225,386.924,483.714,386.924,484.377z
			 M383.229,486.825c0,0.736,0.304,1.104,0.912,1.104c0.544,0,1.08-0.092,1.607-0.276l0.276-0.096v-1.98l-1.776,0.168
			c-0.359,0.032-0.62,0.137-0.78,0.312S383.229,486.489,383.229,486.825z"/>
		<path style="fill:#787878;" d="M389.6,488.565h-0.899v-6h0.888v0.42c0.648-0.36,1.268-0.54,1.86-0.54
			c0.8,0,1.338,0.216,1.613,0.648c0.276,0.432,0.414,1.199,0.414,2.304v3.168h-0.888v-3.144c0-0.832-0.082-1.402-0.246-1.71
			c-0.164-0.309-0.514-0.463-1.05-0.463c-0.256,0-0.526,0.038-0.81,0.114c-0.284,0.076-0.503,0.15-0.654,0.222l-0.229,0.108V488.565
			z"/>
		<path style="fill:#787878;" d="M394.508,482.565h0.9l1.5,5.22h0.396l1.513-5.22h0.899l-2.496,8.664h-0.899l0.779-2.664h-0.888
			L394.508,482.565z"/>
		<path style="fill:#787878;" d="M307.45,506.685c-1.24,0-2.096-0.34-2.568-1.021c-0.472-0.68-0.708-1.75-0.708-3.21
			s0.24-2.544,0.721-3.252c0.479-0.708,1.332-1.062,2.556-1.062s2.078,0.352,2.562,1.056c0.483,0.704,0.726,1.788,0.726,3.252
			c0,1.017-0.104,1.83-0.312,2.442s-0.563,1.066-1.067,1.362l1.02,1.644l-0.863,0.408l-1.057-1.729
			C308.194,506.649,307.858,506.685,307.45,506.685z M305.122,502.449c0,1.217,0.16,2.09,0.48,2.622
			c0.319,0.532,0.938,0.798,1.854,0.798s1.532-0.258,1.849-0.773c0.315-0.517,0.474-1.388,0.474-2.616
			c0-1.228-0.164-2.122-0.492-2.682c-0.328-0.561-0.942-0.841-1.842-0.841c-0.9,0-1.512,0.278-1.836,0.834
			C305.284,500.348,305.122,501.233,305.122,502.449z"/>
		<path style="fill:#787878;" d="M313.354,503.386v3.18h-0.925v-8.304h3.072c0.88,0,1.542,0.204,1.986,0.611
			c0.444,0.408,0.666,1.044,0.666,1.908c0,1.28-0.508,2.084-1.524,2.412l1.597,3.372h-1.021l-1.5-3.18H313.354z M315.513,502.569
			c1.129,0,1.692-0.582,1.692-1.746s-0.568-1.746-1.704-1.746h-2.147v3.492H315.513z"/>
		<path style="fill:#787878;" d="M324.189,501.249c-0.872,0-1.308,0.305-1.308,0.912c0,0.28,0.1,0.479,0.3,0.595
			c0.199,0.115,0.655,0.235,1.368,0.359c0.712,0.124,1.216,0.298,1.512,0.522c0.296,0.224,0.444,0.644,0.444,1.26
			s-0.198,1.068-0.595,1.356c-0.396,0.287-0.974,0.432-1.733,0.432c-0.496,0-1.08-0.057-1.752-0.168l-0.36-0.061l0.048-0.779
			c0.912,0.136,1.6,0.204,2.064,0.204c0.464,0,0.817-0.074,1.062-0.223c0.244-0.147,0.366-0.396,0.366-0.744
			c0-0.348-0.104-0.583-0.312-0.708c-0.208-0.124-0.664-0.241-1.367-0.354c-0.704-0.112-1.204-0.276-1.5-0.492
			c-0.297-0.216-0.444-0.616-0.444-1.2s0.206-1.016,0.618-1.296c0.412-0.279,0.926-0.42,1.542-0.42c0.487,0,1.1,0.057,1.836,0.168
			l0.336,0.061l-0.024,0.768C325.401,501.313,324.701,501.249,324.189,501.249z"/>
		<path style="fill:#787878;" d="M330.129,500.445c0.264,0,0.712,0.048,1.344,0.145l0.288,0.035l-0.036,0.732
			c-0.641-0.072-1.112-0.108-1.416-0.108c-0.68,0-1.143,0.162-1.386,0.486c-0.244,0.324-0.366,0.924-0.366,1.8
			s0.114,1.486,0.342,1.83c0.229,0.345,0.702,0.517,1.422,0.517l1.416-0.108l0.036,0.744c-0.744,0.111-1.3,0.168-1.668,0.168
			c-0.936,0-1.582-0.24-1.938-0.72c-0.356-0.48-0.534-1.29-0.534-2.431c0-1.14,0.191-1.939,0.576-2.399
			C328.593,500.676,329.233,500.445,330.129,500.445z"/>
		<path style="fill:#787878;" d="M337.353,502.377v3.072c0.024,0.296,0.256,0.473,0.696,0.528l-0.036,0.708
			c-0.632,0-1.107-0.16-1.428-0.48c-0.72,0.32-1.44,0.48-2.16,0.48c-0.552,0-0.972-0.156-1.26-0.468
			c-0.288-0.312-0.432-0.761-0.432-1.345c0-0.583,0.147-1.014,0.443-1.29c0.296-0.275,0.76-0.445,1.393-0.51l1.884-0.18v-0.517
			c0-0.407-0.088-0.699-0.265-0.876c-0.176-0.176-0.416-0.264-0.72-0.264c-0.64,0-1.304,0.04-1.992,0.12l-0.372,0.036l-0.035-0.685
			c0.88-0.176,1.659-0.264,2.34-0.264c0.68,0,1.174,0.156,1.481,0.468C337.2,501.225,337.353,501.714,337.353,502.377z
			 M333.658,504.825c0,0.736,0.304,1.104,0.912,1.104c0.544,0,1.08-0.092,1.607-0.276l0.276-0.096v-1.98l-1.776,0.168
			c-0.359,0.032-0.62,0.137-0.78,0.312S333.658,504.489,333.658,504.825z"/>
		<path style="fill:#787878;" d="M340.029,506.565h-0.899v-6h0.888v0.42c0.648-0.36,1.268-0.54,1.86-0.54
			c0.8,0,1.338,0.216,1.613,0.648c0.276,0.432,0.414,1.199,0.414,2.304v3.168h-0.888v-3.144c0-0.832-0.082-1.402-0.246-1.71
			c-0.164-0.309-0.514-0.463-1.05-0.463c-0.256,0-0.526,0.038-0.81,0.114c-0.284,0.076-0.503,0.15-0.654,0.222l-0.229,0.108V506.565
			z"/>
		<path style="fill:#787878;" d="M346.472,506.565h-0.899v-6h0.888v0.42c0.648-0.36,1.268-0.54,1.86-0.54
			c0.8,0,1.338,0.216,1.613,0.648c0.276,0.432,0.414,1.199,0.414,2.304v3.168h-0.888v-3.144c0-0.832-0.082-1.402-0.246-1.71
			c-0.164-0.309-0.514-0.463-1.05-0.463c-0.256,0-0.526,0.038-0.81,0.114c-0.284,0.076-0.503,0.15-0.654,0.222l-0.229,0.108V506.565
			z"/>
		<path style="fill:#787878;" d="M356.097,505.821l0.348-0.036l0.024,0.708c-0.912,0.128-1.692,0.192-2.341,0.192
			c-0.863,0-1.476-0.25-1.836-0.75c-0.359-0.5-0.54-1.278-0.54-2.334c0-2.104,0.836-3.156,2.509-3.156
			c0.808,0,1.411,0.227,1.812,0.678c0.4,0.452,0.6,1.162,0.6,2.131l-0.048,0.684h-3.96c0,0.664,0.12,1.156,0.36,1.476
			c0.24,0.32,0.658,0.48,1.254,0.48S355.481,505.869,356.097,505.821z M355.773,503.218c0-0.736-0.118-1.256-0.354-1.561
			c-0.235-0.304-0.62-0.456-1.151-0.456c-0.532,0-0.933,0.16-1.2,0.48c-0.269,0.32-0.406,0.832-0.414,1.536H355.773z"/>
		<path style="fill:#787878;" d="M358.077,506.565v-6h0.888v0.816c0.696-0.48,1.428-0.796,2.196-0.948v0.912
			c-0.336,0.063-0.687,0.164-1.05,0.3c-0.364,0.136-0.643,0.256-0.834,0.36l-0.301,0.155v4.404H358.077z"/>
	</g>
</g>
<g transform="translate(0 -15)">
<text text-anchor="middle" x="298px" y="635px" style="fill:#333333; font-family:'TitilliumWeb-Regular'; font-size:24px;">` + network + `</text>` +

(!password ? '' :
`<g>
	<path style="fill:#333333;" d="M270.167,679.581h-1.344v2.376h-1.656v-8.16h3c1.936,0,2.904,0.944,2.904,2.832
		c0,0.952-0.246,1.683-0.738,2.19C271.84,679.327,271.118,679.581,270.167,679.581z M268.822,678.165h1.332
		c0.824,0,1.236-0.512,1.236-1.536c0-0.504-0.1-0.866-0.3-1.086c-0.2-0.22-0.512-0.33-0.936-0.33h-1.332V678.165z"/>
	<path style="fill:#333333;" d="M273.322,681.957l1.836-8.16h3.144l1.836,8.16h-1.656l-0.324-1.476h-2.856l-0.324,1.476H273.322z
		 M276.442,675.165l-0.828,3.876h2.232l-0.828-3.876H276.442z"/>
	<path style="fill:#333333;" d="M283.654,675.093c-0.872,0-1.308,0.288-1.308,0.864c0,0.256,0.108,0.452,0.324,0.588
		c0.216,0.137,0.722,0.332,1.518,0.588c0.796,0.256,1.354,0.546,1.674,0.87s0.48,0.822,0.48,1.494c0,0.856-0.26,1.504-0.78,1.944
		c-0.521,0.439-1.2,0.659-2.04,0.659c-0.624,0-1.388-0.096-2.292-0.287l-0.432-0.084l0.168-1.284
		c1.072,0.144,1.892,0.216,2.46,0.216c0.848,0,1.272-0.352,1.272-1.056c0-0.257-0.098-0.456-0.294-0.601
		c-0.196-0.144-0.586-0.3-1.17-0.468c-0.928-0.264-1.582-0.578-1.962-0.942c-0.38-0.363-0.57-0.87-0.57-1.518
		c0-0.816,0.248-1.424,0.744-1.824c0.496-0.399,1.176-0.6,2.04-0.6c0.592,0,1.344,0.08,2.256,0.239l0.432,0.084l-0.132,1.309
		C284.914,675.157,284.118,675.093,283.654,675.093z"/>
	<path style="fill:#333333;" d="M290.182,675.093c-0.872,0-1.308,0.288-1.308,0.864c0,0.256,0.108,0.452,0.324,0.588
		c0.216,0.137,0.722,0.332,1.518,0.588c0.796,0.256,1.354,0.546,1.674,0.87s0.48,0.822,0.48,1.494c0,0.856-0.26,1.504-0.78,1.944
		c-0.521,0.439-1.2,0.659-2.04,0.659c-0.624,0-1.388-0.096-2.292-0.287l-0.432-0.084l0.168-1.284
		c1.072,0.144,1.892,0.216,2.46,0.216c0.848,0,1.272-0.352,1.272-1.056c0-0.257-0.098-0.456-0.294-0.601
		c-0.196-0.144-0.586-0.3-1.17-0.468c-0.928-0.264-1.582-0.578-1.962-0.942c-0.38-0.363-0.57-0.87-0.57-1.518
		c0-0.816,0.248-1.424,0.744-1.824c0.496-0.399,1.176-0.6,2.04-0.6c0.592,0,1.344,0.08,2.256,0.239l0.432,0.084l-0.132,1.309
		C291.442,675.157,290.646,675.093,290.182,675.093z"/>
	<path style="fill:#333333;" d="M293.542,673.797h1.74l1.008,6.744h0.18l1.464-6.744h1.92l1.463,6.744h0.181l1.008-6.744h1.74
		l-1.561,8.16h-2.508l-1.284-6.204l-1.284,6.204h-2.508L293.542,673.797z"/>
	<path style="fill:#333333;" d="M311.008,681.087c-0.508,0.676-1.381,1.014-2.621,1.014c-1.241,0-2.115-0.338-2.623-1.014
		s-0.762-1.733-0.762-3.174s0.254-2.51,0.762-3.21s1.382-1.05,2.623-1.05c1.24,0,2.113,0.35,2.621,1.05s0.762,1.77,0.762,3.21
		S311.516,680.411,311.008,681.087z M307.06,680.031c0.236,0.42,0.679,0.63,1.327,0.63c0.646,0,1.09-0.21,1.326-0.63
		c0.234-0.42,0.354-1.124,0.354-2.112s-0.121-1.706-0.359-2.154c-0.24-0.447-0.682-0.672-1.32-0.672
		c-0.641,0-1.081,0.225-1.321,0.672c-0.239,0.448-0.359,1.166-0.359,2.154S306.824,679.612,307.06,680.031z"/>
	<path style="fill:#333333;" d="M314.842,679.221v2.736h-1.656v-8.16h3.168c1.92,0,2.88,0.896,2.88,2.688
		c0,1.063-0.399,1.848-1.199,2.352l1.164,3.12h-1.812l-0.948-2.736H314.842z M317.254,677.446c0.192-0.24,0.288-0.557,0.288-0.948
		s-0.103-0.704-0.306-0.937c-0.204-0.231-0.498-0.348-0.883-0.348h-1.512v2.592h1.536
		C316.77,677.805,317.062,677.685,317.254,677.446z"/>
	<path style="fill:#333333;" d="M323.374,681.957h-2.748v-8.16h2.748c0.704,0,1.284,0.074,1.74,0.222
		c0.456,0.148,0.81,0.393,1.062,0.732c0.252,0.34,0.426,0.74,0.521,1.2s0.144,1.058,0.144,1.794s-0.044,1.346-0.132,1.83
		s-0.254,0.916-0.498,1.296s-0.598,0.656-1.062,0.828S324.095,681.957,323.374,681.957z M325.114,678.717
		c0.016-0.256,0.024-0.609,0.024-1.062s-0.017-0.814-0.049-1.086c-0.031-0.272-0.107-0.521-0.228-0.744s-0.298-0.378-0.534-0.462
		s-0.554-0.126-0.954-0.126h-1.092v5.279h1.092c0.601,0,1.036-0.151,1.309-0.456C324.914,679.814,325.059,679.365,325.114,678.717z"
		/>
</g>
<text text-anchor="middle" x="298px" y="713px" style="fill:#333333; font-family:'TitilliumWeb-Regular'; font-size:24px;">` + password + `</text>`)

+ `</g></svg>`
}