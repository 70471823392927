const posterProductStyle = (isDesktop) => {
    const credsTitleS = (marginTop) => {
        return {
            height: '24px',
            fontFamily: 'TitilliumWeb-Bold',
            fontSize: '16px',
            color: '#333333',
            marginTop: marginTop
        }
    }

    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',

            paddingTop: isDesktop ? '48px' : '0',

            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: '#fff',

            width: isDesktop ? '620px' : '100%',

            borderRadius: isDesktop ? '10px' : '0',
            height: isDesktop ? '862px' : null
        },
        title: {
            height: '29px',
            fontFamily: 'FredokaOne-Regular',
            fontSize: '24px',
            color: '#333333',
            display: isDesktop ? 'block' : 'none'
        },
        qrContainer: {
            width: isDesktop ? null : '100%',
            marginTop: isDesktop ? '64px' : '23px',
            paddingLeft: isDesktop ? null : '16px',
            paddingRight: isDesktop ? null : '16px'
        },
        descriptionBlock: {
            display: "flex",
            width: "300px",
            marginTop: "16px",
            height: "112px",
            borderRadius: "10px",
            backgroundColor: "#f4f5f6",
            pic: {
                marginTop: "16px",
                marginLeft: "16px",
                width: "89px",
                height: "80px"
            },
            text: {
                marginLeft: '24px',
                marginTop: '21px',
                title: {
                    height: '24px',
                    fontFamily: 'TitilliumWeb-Bold',
                    fontSize: '16px',
                    color: '#333333',
                },
                tip: {
                    marginTop: '4px',
                    height: '42px',
                    fontFamily: 'TitilliumWeb-Regular',
                    fontSize: '14px',
                    color: '#777777'
                }
            }
        },
        credsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            networkTitle: credsTitleS(isDesktop ? '40px' : '32px'),
            passwordTitle: credsTitleS('16px'),
            val: {
                height: '32px',
                fontFamily: 'TitilliumWeb-Regular',
                color: '#333333',
                marginTop: '8px'
            }
        },
        createdWith: {
            height: '21px',
            fontSize: '14px',
            marginTop: isDesktop ? '64px' : '32px',
            marginBottom: isDesktop ? '0' : '16px',
            fontFamily: 'FredokaOne-Regular',
            color: '#333333',
            display: 'flex',
            alignItems: 'center',
            icon: {
                backgroundImage: 'url(/assets/mockup-footer-icn.svg)',
                backgroundSize: '24px 17px',
                display: 'inline-block',
                width: '24px',
                height: '17px'
            }
        }
    }
}

export default posterProductStyle