import React, {useRef} from 'react'
import ReactDOM from 'react-dom'
import {popupStyle} from "./popupStyle";
import {useMediaQuery} from "../utils";

const Popup = (props) => {
    const widthIsMoreThanThreshold = useMediaQuery('(min-width: ' + props.widthAdaptiveThreshold + 'px)')
    const heightIsMoreThanThreshold = useMediaQuery('(min-height: ' + props.heightAdaptiveThreshold + 'px)')
    const s = popupStyle(widthIsMoreThanThreshold,
        heightIsMoreThanThreshold,
        props.widthAdaptiveThreshold,
        props.heightAdaptiveThreshold)

    const containerRef = useRef(null);
    const onBackgroundClick = (e) => {
        const container = ReactDOM.findDOMNode(containerRef.current)
        
        if (e.target === container) {
            props.onClose()
        }
    }


    return (
        <div style={s.popupContainer} ref={containerRef} onClick={onBackgroundClick}>
            <div style={s.popup}>
                <div style={s.popupCloseBtn} onClick={props.onClose}>
                    <img src="/assets/close.svg" alt=""/>
                </div>
                {props.content(widthIsMoreThanThreshold, heightIsMoreThanThreshold)}
            </div>
        </div>
    )
}

export default Popup