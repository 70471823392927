import TagManager from 'react-gtm-module'
import React, {useContext} from "react";
import AppConfig from "../app-config/AppConfig";

export const Analytics = () => {
    const config = useContext(AppConfig);

    if (config.googleTagManager.enabled) {
        TagManager.initialize({
            gtmId: config.googleTagManager.gtmId
        })
    }

    return <></>
}

export const trackEvent = (eventName) => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName
        }
    })
}