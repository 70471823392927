const closeBtnStyle = (isDesktop) => {
    const size = isDesktop ? '48px' : '40px'
    const margin = isDesktop ? '16px' : '8px'
    const iconSize = isDesktop ? '24px' : '16px'

    return {
        btn: {
            position: 'fixed',

            borderRadius: '10px',

            boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: '#343c50',

            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: size,
            height: size,
            top: margin,
            right: margin
        },
        icon: {
            width: iconSize,
            height: iconSize
        }
    }
}

export default closeBtnStyle