import React, {useState} from 'react';
import './CredForm.css';
import classNames from 'classnames';
import SecTypeSelector from './parts/SecTypeSelector';
import Overlay from '../overlay/Overlay';
import {trackEvent} from "../analytics/Analytics";
import {utilizeFocus} from "../utils";

export const CredForm = (props) => {
    const [showExtendedOpts, setShowExtendedOpts] = useState(false);
    const [network, setNetwork] = useState('');
    const [protection, setProtection] = useState('WPA');
    const [password, setPassword] = useState('');
    const passwordInputFocus = utilizeFocus()

    const networkChanged = (event) => setNetwork(event.target.value)
    const passwordChanged = (event) => setPassword(event.target.value)
    const protectionChanged = (val) => setProtection(val)
    const showExtendedOptsClicked = () => {
        if (!showExtendedOpts) {
            trackEvent('extended-opts-shown')
        }
        setShowExtendedOpts(!showExtendedOpts)
    }
    const showPrivacyBtnClicked = () => props.onShowPrivacyBtnClick()

    const generateClicked = () => {
        if (!network) return;

        props.onSubmit({
            network: network,
            password: password,
            protection: protection
        });
    }

    const generateBtnClasses = () => classNames(
        "waflee-btn",
        "primary-waflee-btn",
        "generate-btn",
        { "disabled": !network }
    )

    const onPasswordInputPress = (e) => {
        if (e.key === 'Enter') {
           generateClicked()
        }
    }

    return (
        <div className="form-container">
            <InputWithTooltip tooltipOn={ props.isDesktopMode ? 'hover' : 'click' }
                              value={network}
                              onChange={networkChanged}
                              tooltip="Make sure you are using correct case"
                              onEnterKeyPress={() => passwordInputFocus.setFocus()}
                              autoFocus={true}/>
            
            <input className="form-input"
                   placeholder="Password (if set)"
                   type="text"
                   value={password}
                   onChange={passwordChanged}
                   disabled={protection === 'nopass'}
                   ref={passwordInputFocus.ref}
                   onKeyPress={onPasswordInputPress}/>
            
            <div className="util-buttons-container">
                <div className="waflee-util-btn"
                     onClick={showExtendedOptsClicked}>
                    
                    <img alt="" 
                            className={classNames("extended-opts-arrow-icn", { 'open': showExtendedOpts })}
                            src="/assets/arrow.svg"/>
                    Show extended options
                </div>
                <div className="waflee-util-btn privacy-btn"
                     onClick={showPrivacyBtnClicked}>
                    Is it safe?
                </div>
            </div>
            { 
                showExtendedOpts &&
                <div className="extended-opts-container">
                    <SecTypeSelector value={protection}
                                     onChange={protectionChanged}/>
                </div> 
            }

            <div className={generateBtnClasses()}
                 onClick={generateClicked}>
                Bake it!
            </div>
        </div>
    )
}

const InputWithTooltip = (props) => {
    const isHoverMode = props.tooltipOn === 'hover'
    const isClickMode = props.tooltipOn === 'click'

    const [tooltipShown, setTooltipShown] = useState(false)

    const tooltipContainerPadding = 8
    const tooltipBtnContainerS = {
        padding: tooltipContainerPadding + 'px'
    }

    const tooltipBtnS = {
        cursor: props.tooltipOn === 'click' ? 'pointer' : '',
    }

    const tooltipS = {
        marginTop: isHoverMode ? '-50px' : '',
        marginRight: isHoverMode ? ('-' + tooltipContainerPadding + 'px') : ''
    }

    const onTooltipBtnClick = () => isClickMode && setTooltipShown(true)
    const onTooltipBtnMouseEnter = () => isHoverMode && setTooltipShown(true)
    const onTooltipBtnMouseOut = () => isHoverMode && setTooltipShown(false)
    const onOverlayClick = () => isClickMode && setTooltipShown(false)
    const onInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            props.onEnterKeyPress()
        }
    }

    return (
        <div className="input-with-tooltip--container">
            <input className="form-input input-with-tooltip--input"
                   placeholder="Network"
                   type="text"
                   value={props.value}
                   onChange={props.onChange}
                   onKeyPress={onInputKeyPress}
                   autoFocus={props.autoFocus}>
            </input>
            <div className="input-with-tooltip--tooltip-container"
                 style={tooltipBtnContainerS}>
                <div className="input-with-tooltip--tooltip-btn-container"
                     style={tooltipBtnS}
                     onClick={onTooltipBtnClick}
                     onMouseEnter={onTooltipBtnMouseEnter}
                     onMouseLeave={onTooltipBtnMouseOut}>
                    <img alt="" src="/assets/inf.svg"/>
                </div>

                { tooltipShown &&
                    <div className="input-with-tooltip--tooltip"
                         style={tooltipS}>
                        { props.tooltip }
                    </div>
                }

            </div>
            { tooltipShown && isClickMode &&
                <Overlay onClick={onOverlayClick}></Overlay>
            }
        </div>
    )
    
}

export default CredForm