import React from 'react';

const ToggleBtn = (props) => {
    const s = {
        display: "flex",
        flexDirection: "row",
        height: "48px",
        borderRadius: "6px",
        border: "none",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        padding: "4px",
        ...props.style
    }

    const buttonWidth = (100 / props.buttons.length) + '%'
    const drawBtn = (descriptor) =>
        <Btn key={descriptor.value}
             value={descriptor.value}
             currentValue={props.currentValue}
             onActivate={props.onChange}
             onMouseEnter={descriptor.onMouseEnter}
             onMouseLeave={descriptor.onMouseLeave}
             width={buttonWidth}
             enabled={descriptor.enabled}>
            { descriptor.label }
        </Btn>

    return <div style={s}>
        { props.buttons.map(drawBtn) }
    </div>
}

const Btn = (props) => {
    const onClick = () => {
        if (!props.enabled) {
            return
        }
        props.onActivate(props.value)
    }

    const active = props.value === props.currentValue

    const color = props.enabled
        ? (active ? '#1d2539' : '#fff')
        : 'rgba(255, 255, 255, 0.3)'

    const s = {
        width: props.width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color,
        borderRadius: '4px',
        cursor: props.enabled ? 'pointer' : 'arrow',
        backgroundColor: active ? '#fff' : 'unset',
        fontFamily: 'TitilliumWeb-Regular'
    }

    return (
        <div style={s}
             onClick={onClick}
             onMouseEnter={props.onMouseEnter}
             onMouseLeave={props.onMouseLeave}>
            { props.children }
        </div>
    )
}

export default ToggleBtn