export const popupStyle = (widthIsMoreThanThreshold, heightIsMoreThanThreshold, widthThreshold, heightThreshold) => {
    const s = {}

    s.popupContainer = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }

    s.popup = {
        backgroundColor: 'white',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.15)'
    }

    if (widthIsMoreThanThreshold) {
        s.popup.position = 'relative'
        s.popup.height = heightThreshold + 'px'
        s.popup.width = widthThreshold + 'px'
        s.popup.left = '50%'
        s.popup.marginLeft = '-' + (widthThreshold / 2) + 'px'
        s.popup.borderRadius = '10px'

        if (heightIsMoreThanThreshold) {
            s.popup.marginTop = '-' + (heightThreshold / 2) + 'px'
            s.popup.top = '50%'
        }
        else {
            s.popup.marginTop = '120px'
            s.popup.marginBottom = '120px'
            s.popup.top = 'unset'
        }
    }
    else {
        s.popup.width = '100%'
        s.popup.left = '0'
        s.popup.borderRadius = '10px 10px 0 0'

        if (heightIsMoreThanThreshold) {
            s.popup.bottom = '0'
            s.popup.position = 'fixed'
        }
        else {
            s.popup.marginTop = '20%'
            s.popup.position = 'relative'
        }
    }

    s.popupCloseBtn = {
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        right: '16px',
        top: '16px'
    }

    return s
}
