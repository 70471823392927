import React from "react";
import cardProductStyle from "./cardProductStyle";
import './CardProductPreview.css'

const CardProductPreview = (props) => {
    const s = cardProductStyle(props.isDesktop)

    return (
    <div style={s.container}>
        <div style={s.title}>FREE WI-FI</div>
        <div style={s.descriptionBlock}>
            <img style={s.descriptionBlock.pic}
                 alt=""
                 src="/assets/card-tip.svg"/>
            <div style={s.descriptionBlock.text}>
                <span style={s.descriptionBlock.text.emphasis}>Scan it</span> with camera or any QR scanner
            </div>
        </div>
        <div style={s.qrContainer}
             className="card-prdct-qr"
             dangerouslySetInnerHTML={{ __html: props.qrCodeSvgStr }}/>
        <div style={s.credsContainer}>
            <div style={s.credsContainer.network}>Network <span style={s.credsContainer.val}>{props.network}</span></div>
            <div style={s.credsContainer.password}>{ props.password && "Password" } <span style={s.credsContainer.val}>{props.password}</span></div>
        </div>
        <div style={s.createdWith}><div style={s.createdWith.icon}/>waflee.app</div>
    </div>
    );
}

export default CardProductPreview