import React from 'react'
import './HowItWorksDialog.css'
import Carousel from 'react-bootstrap/Carousel'
import classNames from 'classnames'
import Popup from '../popup/Popup'
import {trackEvent} from "../analytics/Analytics";

export default class HowItWorksDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentPage: 0 };
    }

    changePage = (n) => {
        trackEvent('how-it-works-page-changed-' + n)
        this.setState({ currentPage: n })
    }
    prevBtnClick = () => this.changePage(Math.max(this.state.currentPage - 1, 0))
    nextBtnClick = () => {
        if (this.state.currentPage === 3) {
            this.props.onClose()
            return
        }
        this.changePage(Math.min(this.state.currentPage + 1, 3))
    }

    prevBtnClasses = () => classNames(
        "hiw-btn",
        "waflee-btn",
        "hiw-prev-btn",
        { "disabled": this.state.currentPage === 0 }
    )

    titleS = (isDesktop) => {
        return {
            paddingTop: isDesktop ? '64px' : '48px',
            fontFamily: 'FredokaOne-Regular',
            fontSize: '24px',
            textAlign: 'center',
            color: '#333333'
        }
    }

    carouselContainerS = (isDesktop) => {
        return {
            marginTop: isDesktop ? '64px' : '48px'
        }
    }

    dialogContent = (widthIsMoreThanThreshold, heightIsMoreThanThreshold) =>
        <>
            <div style={this.titleS(widthIsMoreThanThreshold)}>How it works?</div>
            <div style={this.carouselContainerS(widthIsMoreThanThreshold)}>

                <Carousel activeIndex={this.state.currentPage} 
                        interval={null}
                        controls={false}
                        indicators={false}
                        onSelect={this.changePage}>
                    { this.item(0, <>Enter the parameters<br/>of your network</>) }
                    { this.item(1, <>We generate a print-ready design<br/>with these parameters</>) }
                    { this.item(2, <>Print it on your own printer<br/>or use the services of a printing house</>) }
                    { this.item(3, <>Your guests will be able to connect<br/>to your network with a simple scan</>) }
                </Carousel>
            </div>
            
            <div className="how-it-works-dots-panel">
                { [0, 1, 2, 3].map(n => 
                        <Dot num={n} 
                            currentPage={this.state.currentPage} 
                            onClick={this.changePage}
                            key={n}>
                        </Dot>
                    ) 
                }
            </div>

            <div className="how-it-works-buttons">
                <div className={this.prevBtnClasses()} onClick={this.prevBtnClick}>Previous</div>
                <div className="hiw-btn waflee-btn primary-waflee-btn hiw-next-btn" 
                        onClick={this.nextBtnClick}>
                    { this.state.currentPage === 3 ? "Finish" : "Next" }
                </div>
            </div>
            <div className="how-it-works-buttons-bottom-gap"/>
        </>

    render = () => (
        <Popup title="How it works?"
               onClose={this.props.onClose}
               widthAdaptiveThreshold={460}
               heightAdaptiveThreshold={540}
               content={this.dialogContent}>
        </Popup>
    )

    item = (num, desc) => (
        <Carousel.Item>
            <div className="how-it-works-car-img-container">
                <img className="how-it-works-car-img"
                     src={"/assets/hiw/step-" + num + ".svg"}
                     alt="How it works"/>
            </div>
            <div className="how-it-works-car-text">{desc}</div>
        </Carousel.Item>
    )
}

class Dot extends React.Component {
    onDotClick = () => this.props.onClick(this.props.num)
    dotClasses = () => classNames(
        "how-it-works-dot", 
        { "active": this.props.num === this.props.currentPage } 
    )

    render = () => (
        <div className="how-it-works-dot-container" onClick={this.onDotClick}>
            <div className={this.dotClasses()}></div>
        </div>
    )
}
