
const bottomSheetStyle = (isDesktop, isOpen) => {
    const customizationBlockVPadding = '24px'
    const customizationBlockHPadding = '16px'
    const customizationBlockHeight = 192

    return {
        sheet: {
            borderRadius: '10px 10px 0 0',
            boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: '#343c50',

            position: 'fixed',
            bottom: (isOpen || isDesktop) ? '0' : ('-' + customizationBlockHeight + 'px'),

            width: '100%'
        },
        bar: {
            height: '80px',

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            padding: '16px'
        },
        getBtn: {
            width: isDesktop ? '290px' : '160px',
            marginLeft: 'auto'
        },
        productSelector: {
            width: isDesktop ? '480px' : '100%',
            marginRight: '16px'
        },
        customizeBtn: {
            paddingLeft: '16px',
            paddingRight: '16px',

            height: '48px',
            lineHeight: '48px',

            cursor: 'pointer',

            borderRadius: '8px',

            fontFamily: 'TitilliumWeb-Regular',

            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',

            whiteSpace: 'nowrap',

            color: '#fff',
            icon: {
                marginRight: '10px',
                display: 'inline-block',
                verticalAlign: 'sub'
            }
        },
        customizationBlock: {
            height: customizationBlockHeight + 'px',
            paddingLeft: customizationBlockHPadding,
            paddingRight: customizationBlockHPadding,
            paddingTop: customizationBlockVPadding,
            paddingBottom: customizationBlockVPadding,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)'
        }
    }
}

export default bottomSheetStyle