import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import App from './app/App'

window.RenderApp = (config) => {
    ReactDOM.render(<App config={config}/>, document.getElementById('app'));
}


serviceWorker.register();
