const cardProductStyle = (isDesktop) => {
    const credsTitleS = (marginTop) => {
        return {
            height: '24px',
            fontSize: '16px',
            color: '#333333',
            marginTop: marginTop
        }
    }

    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',

            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: '#fff',

            width: isDesktop ? '400px' : '320px',

            borderRadius: '10px',
            height: '623px',

            paddingTop: '32px'
        },
        title: {
            height: '29px',
            fontFamily: 'FredokaOne-Regular',
            fontSize: '24px',
            color: '#333333',
        },
        descriptionBlock: {
            display: 'flex',
            width: '260px',
            marginTop: '33px',
            height: '70px',

            pic: {
                width: '89px',
                height: '70px'
            },
            text: {
                marginLeft: '21px',
                marginTop: '11px',
                height: '48px',
                fontFamily: 'TitilliumWeb-Regular',
                fontSize: '16px',
                textAlign: 'center',
                color: '#333333',
                emphasis: {
                    fontFamily: 'TitilliumWeb-Bold',
                }
            }
        },
        qrContainer: {
            width: '260px',
            marginTop: '32px',
        },
        credsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            network: credsTitleS('33px'),
            password: credsTitleS('16px'),
            fontFamily: 'TitilliumWeb-Bold',
            val: {
                fontFamily: 'TitilliumWeb-Regular'
            }
        },
        createdWith: {
            height: '21px',
            fontSize: '14px',
            marginTop: '32px',
            fontFamily: 'FredokaOne-Regular',
            color: '#333333',
            display: 'flex',
            alignItems: 'center',
            icon: {
                backgroundImage: 'url(/assets/mockup-footer-icn.svg)',
                backgroundSize: '24px 17px',
                display: 'inline-block',
                width: '24px',
                height: '17px'
            }
        }
    }
}

export default cardProductStyle