import React, {useEffect, useState} from "react";

export const useMediaQuery = (query) => {
    const mediaQuery = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaQuery.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    })

    return matches
}

export const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current &&  ref.current.focus()}

    return {setFocus, ref}
}