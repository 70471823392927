const layoutPreviewDialogStyle = (isDesktop) => {
    return {
        dialog: {
            position: 'fixed',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0'
        },
        contentContainer: {
            position: 'fixed',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            overflowY: 'auto'
        },
        content: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '100px',
            width: isDesktop ? '620px' : '100%'
        },
        header: {
            fontFamily: 'FredokaOne-Regular',
            color: '#ffffff',
            textAlign: 'center',
            marginTop: '48px',
            marginBottom: '0',
            fontSize: isDesktop ? '40px' : '24px'
        },
        explanation: {
            fontFamily: 'TitilliumWeb-Regular',
            opacity: '0.7',
            fontSize: isDesktop ? '16px' : '14px',
            textAlign: 'center',
            color: '#ffffff',
            marginTop: '24px',
            marginLeft: isDesktop ? '0' : '16px',
            marginRight: isDesktop ? '0' : '16px'
        },
        previewContainer: {
            marginTop: '48px',
            marginBottom: '48px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}

export default layoutPreviewDialogStyle