import React from 'react';
import './HowItWorksBtn.css'

export class HowItWorksBtn extends React.Component {
    render() {
        return (
            <div className="waflee-util-btn" 
                  onClick={this.props.onClick}>
                <img alt="" className="how-it-works-inf-icn" src="/assets/inf.svg"></img>How it works?
            </div>
        )
    }
}

export default HowItWorksBtn;