import React from 'react';
import './VirtualProductPreview.css'

const VirtualProductPreview = (props) => {
    const containerS = {
        width: 'calc(100vw - 32px - 32px - 16px)',
        borderRadius: '10px',
        marginLeft: '16px',
        marginRight: '16px',
        backgroundColor: '#ffffff',
        paddingTop: '32px',
        paddingBottom: '32px',
        paddingLeft: '24px',
        paddingRight: '24px',
    }

    const credS = (isFirst) => {
        return {
            fontFamily: 'TitilliumWeb-Regular',
            fontSize: '16px',
            textAlign: 'center',
            paddingTop: isFirst ? '32px' : '16px'
        }
    }

    const credTitleS = {
        display: 'inline',
        fontFamily: 'TitilliumWeb-Bold'
    }

    return (
        <div style={containerS}>
            <div className="virtual-card-prdct-qr"
                 dangerouslySetInnerHTML={{ __html: props.qrCodeSvgStr }}/>
            <div style={credS(true)}><div style={credTitleS}>Network </div>{ props.network }</div>
            { props.password && <div style={credS(false)}><div style={credTitleS}>Password </div>{ props.password }</div> }
        </div>
    )
}

export default VirtualProductPreview