export default function cardTemplate(network, password, qrCodeSvgStr) {
    const qrCodeFormatted = qrCodeSvgStr.replace(
        /width="\d+px"/g,
        ''
    ).replace(
        /height="\d+px"/g,
        'width="103px" height="103px" x="26px" y="77px"'
    );

    const networkSvg = `<text text-anchor="middle" x="78px" y="198px" style="fill:#333333; font-family:'TitilliumWeb-Regular'; font-size:8px;"><tspan style="font-family:'TitilliumWeb-Bold'">Network </tspan>` + network + `</text>`
    const passwordSvg = `<text text-anchor="middle" x="78px" y="211px" style="fill:#333333; font-family:'TitilliumWeb-Regular'; font-size:8px;"><tspan style="font-family:'TitilliumWeb-Bold'">Password </tspan>` + password + `</text>`

    return `
    <?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 155.906 240.945" style="enable-background:new 0 0 155.906 240.945;" xml:space="preserve">
`
        + qrCodeFormatted
        + `
<g id="logo_bottom">
	<path id="icon" style="fill:#020203;" d="M57.258,227.576c-0.28,0.232-0.291,0.662-0.035,0.919l4.191,4.191
		c0.245,0.245,0.643,0.245,0.888,0l4.191-4.191c0.256-0.256,0.245-0.687-0.035-0.919C63.796,225.365,59.918,225.365,57.258,227.576z
		 M62.409,227.057c-0.004-0.004-0.011-0.011-0.013-0.018c0.446,0.035,0.894,0.117,1.329,0.247l-0.433,0.433
		c-0.062,0.062-0.159,0.062-0.221,0L62.409,227.057z M59.989,227.286c0.435-0.13,0.883-0.212,1.329-0.247
		c-0.002,0.007-0.009,0.013-0.013,0.018l-0.662,0.662c-0.062,0.062-0.159,0.062-0.221,0L59.989,227.286z M62.63,230.81
		c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662c-0.062-0.062-0.062-0.159,0-0.221
		l0.662-0.662c0.062-0.062,0.159-0.062,0.221,0L62.63,230.81z M61.305,229.486c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662
		c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662c-0.062-0.062-0.062-0.159,0-0.221l0.662-0.662c0.062-0.062,0.159-0.062,0.221,0
		L61.305,229.486z M59.98,228.161c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662
		c-0.062-0.062-0.062-0.159,0-0.221l0.424-0.424c0.157-0.082,0.316-0.152,0.477-0.221L59.98,228.161z M63.955,229.486
		c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662c-0.062-0.062-0.062-0.159,0-0.221
		l0.662-0.662c0.062-0.062,0.159-0.062,0.221,0L63.955,229.486z M62.63,228.161c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662
		c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662c-0.062-0.062-0.062-0.159,0-0.221l0.662-0.662c0.062-0.062,0.159-0.062,0.221,0
		L62.63,228.161z M65.28,228.161c0.062,0.062,0.062,0.159,0,0.221l-0.662,0.662c-0.062,0.062-0.159,0.062-0.221,0l-0.662-0.662
		c-0.062-0.062-0.062-0.159,0-0.221l0.645-0.645c0.161,0.068,0.32,0.139,0.477,0.221L65.28,228.161z"/>
	<g id="text_1_">
		<path d="M71.787,228.741c0.227,0.099,0.341,0.221,0.341,0.365c0,0.06-0.028,0.156-0.085,0.289s-0.105,0.248-0.144,0.347
			l-0.586,1.419c-0.053,0.156-0.142,0.265-0.266,0.328c-0.053,0.025-0.121,0.037-0.202,0.037s-0.161-0.031-0.237-0.093
			c-0.077-0.062-0.127-0.125-0.152-0.188l-0.042-0.09c-0.029-0.071-0.133-0.346-0.314-0.826c-0.036,0.084-0.088,0.223-0.157,0.415
			c-0.069,0.193-0.114,0.312-0.133,0.36c-0.02,0.048-0.032,0.079-0.037,0.095c-0.005,0.016-0.02,0.045-0.045,0.087
			c-0.025,0.042-0.053,0.079-0.085,0.111c-0.085,0.085-0.183,0.127-0.293,0.127s-0.202-0.031-0.277-0.093
			c-0.075-0.062-0.124-0.125-0.149-0.188l-0.804-1.948c-0.036-0.106-0.053-0.175-0.053-0.207c0-0.141,0.101-0.252,0.303-0.333
			c0.114-0.046,0.206-0.069,0.277-0.069s0.129,0.015,0.173,0.045c0.044,0.03,0.075,0.06,0.093,0.09
			c0.018,0.03,0.032,0.061,0.043,0.093c0.06,0.141,0.186,0.473,0.378,0.995l0.314-0.858c0.021-0.07,0.06-0.132,0.117-0.185
			c0.103-0.102,0.208-0.153,0.314-0.153c0.107,0,0.198,0.028,0.274,0.085c0.076,0.056,0.129,0.113,0.157,0.169l0.043,0.09
			c0.178,0.494,0.282,0.776,0.314,0.847l0.325-0.879c0.085-0.226,0.213-0.339,0.383-0.339
			C71.641,228.688,71.712,228.706,71.787,228.741z"/>
		<path d="M74.136,229.016c0.021-0.197,0.161-0.296,0.421-0.296c0.138,0,0.241,0.011,0.309,0.032
			c0.067,0.021,0.114,0.058,0.141,0.111c0.027,0.053,0.043,0.1,0.048,0.14c0.005,0.04,0.008,0.101,0.008,0.183v1.853
			c0,0.081-0.003,0.142-0.008,0.183c-0.005,0.041-0.02,0.087-0.045,0.14c-0.05,0.092-0.171,0.137-0.365,0.137
			s-0.323-0.024-0.389-0.071c-0.066-0.048-0.104-0.118-0.114-0.209c-0.132,0.187-0.351,0.28-0.658,0.28s-0.592-0.139-0.855-0.418
			c-0.263-0.279-0.394-0.603-0.394-0.972s0.132-0.692,0.397-0.969c0.265-0.277,0.555-0.415,0.871-0.415
			c0.121,0,0.231,0.021,0.33,0.063s0.169,0.082,0.208,0.119C74.079,228.945,74.111,228.981,74.136,229.016z M73.166,230.117
			c0,0.117,0.046,0.221,0.138,0.315c0.092,0.094,0.204,0.14,0.336,0.14s0.241-0.048,0.328-0.143c0.087-0.095,0.13-0.2,0.13-0.315
			c0-0.115-0.042-0.221-0.125-0.32c-0.083-0.099-0.195-0.148-0.336-0.148s-0.254,0.049-0.341,0.148
			C73.21,229.893,73.166,230.001,73.166,230.117z"/>
		<path d="M77.129,228.725c0.245,0,0.389,0.067,0.432,0.201c0.021,0.067,0.032,0.156,0.032,0.265s-0.012,0.197-0.035,0.262
			c-0.023,0.065-0.06,0.112-0.109,0.14c-0.075,0.042-0.183,0.063-0.325,0.063l-0.341-0.021v1.419c0,0.134-0.017,0.234-0.05,0.299
			c-0.034,0.065-0.085,0.108-0.155,0.13c-0.069,0.021-0.159,0.031-0.269,0.031c-0.11,0-0.198-0.011-0.264-0.034
			c-0.066-0.023-0.113-0.06-0.141-0.111c-0.028-0.051-0.045-0.098-0.051-0.14c-0.005-0.043-0.008-0.104-0.008-0.185v-1.403h-0.192
			c-0.092-0.007-0.158-0.028-0.197-0.063c-0.068-0.067-0.101-0.196-0.101-0.386c0-0.29,0.105-0.434,0.314-0.434h0.176v-0.28
			c0-0.272,0.098-0.518,0.293-0.738c0.195-0.221,0.478-0.331,0.847-0.331c0.082,0,0.143,0.003,0.184,0.008
			c0.041,0.006,0.088,0.022,0.141,0.05c0.096,0.046,0.144,0.182,0.144,0.408c0,0.169-0.03,0.291-0.091,0.365
			c-0.05,0.056-0.128,0.088-0.235,0.095c-0.032,0-0.082,0-0.149,0c-0.131,0-0.197,0.044-0.197,0.132v0.291L77.129,228.725z
			 M77.87,227.873c0-0.081,0.003-0.142,0.008-0.183c0.005-0.04,0.022-0.087,0.051-0.14c0.046-0.096,0.183-0.143,0.41-0.143
			c0.217,0,0.352,0.047,0.405,0.143c0.029,0.053,0.046,0.1,0.051,0.143c0.006,0.043,0.008,0.104,0.008,0.185v2.451
			c0,0.127,0.01,0.207,0.032,0.241c0.022,0.034,0.068,0.05,0.141,0.05s0.122,0.003,0.147,0.008c0.025,0.005,0.057,0.02,0.096,0.045
			c0.068,0.046,0.101,0.171,0.101,0.376c0,0.222-0.034,0.358-0.101,0.408c-0.096,0.064-0.318,0.074-0.666,0.032
			c-0.274-0.035-0.453-0.125-0.538-0.27c-0.096-0.173-0.144-0.448-0.144-0.826V227.873z"/>
		<path d="M82.264,229.781c0,0.217-0.054,0.378-0.163,0.482c-0.108,0.104-0.228,0.156-0.359,0.156H80.48
			c0,0.099,0.059,0.182,0.176,0.249c0.117,0.067,0.235,0.101,0.352,0.101c0.206,0,0.368-0.021,0.485-0.064l0.059-0.021
			c0.085-0.039,0.156-0.058,0.213-0.058c0.114,0,0.211,0.079,0.293,0.238c0.046,0.095,0.069,0.177,0.069,0.243
			c0,0.314-0.378,0.471-1.134,0.471c-0.263,0-0.496-0.045-0.7-0.135c-0.204-0.09-0.363-0.209-0.477-0.357
			c-0.224-0.286-0.336-0.605-0.336-0.958c0-0.445,0.145-0.801,0.434-1.067c0.289-0.267,0.651-0.4,1.084-0.4
			c0.494,0,0.856,0.173,1.087,0.519C82.204,229.364,82.264,229.564,82.264,229.781z M81.14,229.937c0.135,0,0.202-0.056,0.202-0.169
			c0-0.081-0.031-0.146-0.093-0.196c-0.062-0.05-0.151-0.074-0.266-0.074c-0.116,0-0.23,0.047-0.344,0.14
			c-0.114,0.093-0.17,0.193-0.17,0.299H81.14z"/>
		<path d="M85.316,229.781c0,0.217-0.054,0.378-0.163,0.482c-0.108,0.104-0.228,0.156-0.359,0.156h-1.263
			c0,0.099,0.059,0.182,0.176,0.249c0.117,0.067,0.235,0.101,0.352,0.101c0.206,0,0.368-0.021,0.485-0.064l0.059-0.021
			c0.085-0.039,0.156-0.058,0.213-0.058c0.114,0,0.211,0.079,0.293,0.238c0.046,0.095,0.069,0.177,0.069,0.243
			c0,0.314-0.378,0.471-1.134,0.471c-0.263,0-0.496-0.045-0.7-0.135c-0.204-0.09-0.363-0.209-0.477-0.357
			c-0.224-0.286-0.336-0.605-0.336-0.958c0-0.445,0.145-0.801,0.434-1.067c0.289-0.267,0.651-0.4,1.084-0.4
			c0.494,0,0.856,0.173,1.087,0.519C85.256,229.364,85.316,229.564,85.316,229.781z M84.192,229.937
			c0.135,0,0.202-0.056,0.202-0.169c0-0.081-0.031-0.146-0.093-0.196c-0.062-0.05-0.151-0.074-0.266-0.074
			c-0.116,0-0.23,0.047-0.344,0.14s-0.17,0.193-0.17,0.299H84.192z"/>
		<path d="M86.488,230.636c0.06,0.06,0.091,0.184,0.091,0.371v0.021c0,0.084-0.003,0.147-0.008,0.188
			c-0.006,0.04-0.024,0.089-0.056,0.146c-0.053,0.103-0.188,0.153-0.402,0.153c-0.215,0-0.357-0.033-0.426-0.1
			c-0.069-0.067-0.104-0.194-0.104-0.381v-0.021c0-0.081,0.003-0.143,0.008-0.185c0.005-0.042,0.022-0.09,0.05-0.143
			c0.053-0.106,0.187-0.159,0.402-0.159C86.258,230.525,86.406,230.562,86.488,230.636z"/>
		<path d="M88.746,229.016c0.021-0.197,0.162-0.296,0.421-0.296c0.138,0,0.241,0.011,0.309,0.032
			c0.068,0.021,0.115,0.058,0.141,0.111c0.027,0.053,0.042,0.1,0.048,0.14c0.005,0.04,0.008,0.101,0.008,0.183v1.853
			c0,0.081-0.003,0.142-0.008,0.183c-0.006,0.041-0.021,0.087-0.046,0.14c-0.05,0.092-0.171,0.137-0.365,0.137
			c-0.194,0-0.323-0.024-0.389-0.071c-0.065-0.048-0.104-0.118-0.114-0.209c-0.131,0.187-0.351,0.28-0.658,0.28
			c-0.307,0-0.592-0.139-0.855-0.418c-0.263-0.279-0.394-0.603-0.394-0.972s0.132-0.692,0.397-0.969
			c0.264-0.277,0.555-0.415,0.871-0.415c0.121,0,0.231,0.021,0.33,0.063c0.099,0.042,0.169,0.082,0.208,0.119
			C88.69,228.945,88.722,228.981,88.746,229.016z M87.777,230.117c0,0.117,0.046,0.221,0.138,0.315
			c0.092,0.094,0.204,0.14,0.336,0.14c0.131,0,0.241-0.048,0.328-0.143s0.13-0.2,0.13-0.315c0-0.115-0.042-0.221-0.125-0.32
			c-0.083-0.099-0.195-0.148-0.336-0.148c-0.14,0-0.254,0.049-0.341,0.148C87.82,229.893,87.777,230.001,87.777,230.117z"/>
		<path d="M90.1,232.267v-3.092c0-0.081,0.003-0.141,0.008-0.18c0.005-0.039,0.02-0.083,0.045-0.132
			c0.05-0.095,0.169-0.143,0.357-0.143c0.188,0,0.315,0.024,0.381,0.072c0.066,0.048,0.104,0.118,0.114,0.209
			c0.135-0.183,0.347-0.275,0.637-0.275c0.289,0,0.564,0.136,0.823,0.408s0.389,0.588,0.389,0.947c0,0.36-0.129,0.676-0.386,0.948
			c-0.258,0.272-0.532,0.408-0.823,0.408c-0.291,0-0.502-0.081-0.634-0.243v1.069c0,0.081-0.003,0.141-0.008,0.18
			c-0.005,0.039-0.021,0.084-0.048,0.135c-0.027,0.051-0.072,0.087-0.136,0.109c-0.064,0.021-0.151,0.032-0.261,0.032
			c-0.11,0-0.198-0.011-0.264-0.032c-0.066-0.021-0.112-0.056-0.138-0.106c-0.027-0.05-0.043-0.093-0.048-0.132
			C90.102,232.408,90.1,232.348,90.1,232.267z M91.154,229.773c-0.082,0.095-0.123,0.199-0.123,0.312
			c0,0.113,0.042,0.216,0.125,0.309c0.083,0.094,0.19,0.14,0.319,0.14s0.24-0.046,0.33-0.137c0.091-0.092,0.136-0.196,0.136-0.312
			c0-0.117-0.042-0.221-0.128-0.315c-0.085-0.094-0.196-0.14-0.333-0.14C91.345,229.63,91.236,229.678,91.154,229.773z"/>
		<path d="M93.199,232.267v-3.092c0-0.081,0.003-0.141,0.008-0.18c0.005-0.039,0.02-0.083,0.045-0.132
			c0.05-0.095,0.169-0.143,0.357-0.143c0.188,0,0.315,0.024,0.381,0.072c0.066,0.048,0.104,0.118,0.114,0.209
			c0.135-0.183,0.347-0.275,0.637-0.275c0.289,0,0.564,0.136,0.823,0.408s0.389,0.588,0.389,0.947c0,0.36-0.129,0.676-0.386,0.948
			c-0.258,0.272-0.532,0.408-0.823,0.408c-0.291,0-0.502-0.081-0.634-0.243v1.069c0,0.081-0.003,0.141-0.008,0.18
			c-0.005,0.039-0.021,0.084-0.048,0.135c-0.027,0.051-0.072,0.087-0.136,0.109c-0.064,0.021-0.151,0.032-0.261,0.032
			s-0.198-0.011-0.264-0.032c-0.066-0.021-0.112-0.056-0.138-0.106c-0.027-0.05-0.043-0.093-0.048-0.132
			C93.202,232.408,93.199,232.348,93.199,232.267z M94.254,229.773c-0.082,0.095-0.123,0.199-0.123,0.312
			c0,0.113,0.042,0.216,0.125,0.309c0.083,0.094,0.19,0.14,0.319,0.14s0.24-0.046,0.33-0.137c0.091-0.092,0.136-0.196,0.136-0.312
			c0-0.117-0.042-0.221-0.128-0.315c-0.085-0.094-0.196-0.14-0.333-0.14C94.445,229.63,94.335,229.678,94.254,229.773z"/>
	</g>
</g>
<g id="tip">
	<g id="illustration">
		<g>
			<g>
				<g>
					<path style="fill:#F4F5F6;" d="M40.09,42.196v23.325c0,0.605-0.488,1.093-1.093,1.093H26.605c-0.605,0-1.093-0.488-1.093-1.093
						V42.196c0-0.605,0.488-1.093,1.093-1.093h12.391C39.602,41.102,40.09,41.591,40.09,42.196z"/>
				</g>
				<g>
					<rect x="30.979" y="49.12" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M30.614,50.214h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093c0-0.201-0.163-0.364-0.364-0.364
						h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093C30.25,50.051,30.413,50.214,30.614,50.214z M30.614,48.756h1.093v1.093h-1.093
						V48.756z"/>
					<rect x="30.979" y="53.129" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M31.708,52.4h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093c0,0.201,0.163,0.364,0.364,0.364
						h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093C32.072,52.564,31.909,52.4,31.708,52.4z M31.708,53.858h-1.093v-1.093h1.093
						V53.858z"/>
					<rect x="34.988" y="49.12" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M35.717,48.391h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093c0,0.201,0.163,0.364,0.364,0.364
						h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093C36.081,48.555,35.918,48.391,35.717,48.391z M35.717,49.849h-1.093v-1.093
						h1.093V49.849z"/>
					<rect x="33.165" y="48.391" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="33.53,50.214 33.53,49.485 33.165,49.485 33.165,49.849 32.436,49.849 32.436,50.214 
						33.165,50.214 					"/>
					<rect x="30.25" y="50.578" style="fill:#4F4B49;" width="0.729" height="0.364"/>
					<rect x="31.343" y="50.578" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="30.979,51.672 31.708,51.672 31.708,52.036 32.072,52.036 32.072,51.672 32.072,51.307 
						30.979,51.307 					"/>
					<rect x="30.614" y="51.672" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="32.436,50.943 33.894,50.943 33.894,50.578 32.436,50.578 32.072,50.578 32.072,51.307 
						32.436,51.307 					"/>
					<rect x="32.436" y="51.672" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="33.894,52.036 33.165,52.036 33.165,52.4 34.259,52.4 34.259,52.036 34.259,51.672 
						33.894,51.672 					"/>
					<rect x="34.988" y="50.578" style="fill:#4F4B49;" width="0.729" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="35.352,52.036 35.717,52.036 35.717,51.672 35.352,51.672 35.352,51.307 34.988,51.307 
						34.988,52.036 34.623,52.036 34.623,52.765 34.988,52.765 34.988,52.4 35.352,52.4 					"/>
					<polygon style="fill:#4F4B49;" points="35.352,52.765 35.352,54.223 35.717,54.223 35.717,53.129 36.081,53.129 36.081,52.765 
						35.717,52.765 					"/>
					<rect x="34.259" y="50.578" style="fill:#4F4B49;" width="0.364" height="1.093"/>
					<rect x="33.165" y="52.765" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="33.894,53.494 34.623,53.494 34.623,53.858 34.988,53.858 34.988,53.494 34.988,53.129 
						33.894,53.129 					"/>
					<rect x="32.436" y="52.765" style="fill:#4F4B49;" width="0.364" height="1.093"/>
					<rect x="33.165" y="53.858" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="33.165" y="51.307" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="32.436" y="49.12" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="32.436" y="48.391" style="fill:#4F4B49;" width="0.364" height="0.364"/>
				</g>
				<path style="fill:#B5B2B1;" d="M35.511,58.232H30.82c-0.114,0-0.206-0.092-0.206-0.206v-0.317c0-0.114,0.092-0.206,0.206-0.206
					h4.691c0.114,0,0.206,0.092,0.206,0.206v0.317C35.717,58.14,35.624,58.232,35.511,58.232z"/>
				<path style="fill:#B5B2B1;" d="M34.782,59.69h-3.233c-0.114,0-0.206-0.092-0.206-0.206v-0.317c0-0.114,0.092-0.206,0.206-0.206
					h3.233c0.114,0,0.206,0.092,0.206,0.206v0.317C34.988,59.597,34.896,59.69,34.782,59.69z"/>
			</g>
			<g>
				<g>
					<path style="fill:#5895D0;" d="M58.147,42.196v23.325c0,0.604-0.49,1.093-1.093,1.093H46.485c-0.805,0-1.458-0.653-1.458-1.458
						V42.56c0-0.805,0.653-1.458,1.458-1.458h10.569C57.658,41.102,58.147,41.592,58.147,42.196z"/>
				</g>
				<path style="fill:#5B5856;" d="M58.876,42.196v23.325c0,0.605-0.488,1.093-1.093,1.093H46.849c-0.605,0-1.093-0.488-1.093-1.093
					V42.196c0-0.605,0.488-1.093,1.093-1.093h10.934C58.388,41.102,58.876,41.591,58.876,42.196z"/>
				<g>
					<path style="fill:#F4F5F6;" d="M58.512,42.196v23.325c0,0.401-0.328,0.729-0.729,0.729H46.849
						c-0.401,0-0.729-0.328-0.729-0.729V42.196c0-0.401,0.328-0.729,0.729-0.729h3.28v0.729c0,0.2,0.164,0.364,0.364,0.364h4.373
						c0.2,0,0.364-0.164,0.364-0.364v-0.729h2.551C58.184,41.467,58.512,41.795,58.512,42.196z"/>
				</g>
				<path style="fill:#A7A4A3;" d="M57.689,66.25H46.943c-0.454,0-0.823-0.368-0.823-0.823v-3.551h12.391v3.551
					C58.512,65.881,58.144,66.25,57.689,66.25z"/>
				<path style="fill:#FFFFFF;" d="M54.38,65.885h-3.398c-0.068,0-0.123-0.055-0.123-0.123v-0.118c0-0.068,0.055-0.123,0.123-0.123
					h3.398c0.068,0,0.123,0.055,0.123,0.123v0.118C54.503,65.83,54.448,65.885,54.38,65.885z"/>
				<g>
					<circle style="fill:#FFFFFF;" cx="52.681" cy="63.699" r="0.729"/>
				</g>
				<g>
					<rect x="50.494" y="48.027" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M50.129,49.12h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093c0-0.201-0.163-0.364-0.364-0.364
						h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093C49.765,48.957,49.928,49.12,50.129,49.12z M50.129,47.663h1.093v1.093h-1.093
						V47.663z"/>
					<rect x="50.494" y="52.036" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M51.223,51.307h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093c0,0.201,0.163,0.364,0.364,0.364
						h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093C51.587,51.47,51.424,51.307,51.223,51.307z M51.223,52.765h-1.093v-1.093h1.093
						V52.765z"/>
					<rect x="54.503" y="48.027" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<path style="fill:#4F4B49;" d="M55.232,47.298h-1.093c-0.201,0-0.364,0.163-0.364,0.364v1.093c0,0.201,0.163,0.364,0.364,0.364
						h1.093c0.201,0,0.364-0.163,0.364-0.364v-1.093C55.596,47.461,55.433,47.298,55.232,47.298z M55.232,48.756h-1.093v-1.093
						h1.093V48.756z"/>
					<rect x="52.681" y="47.298" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="53.045,49.12 53.045,48.391 52.681,48.391 52.681,48.756 51.952,48.756 51.952,49.12 
						52.681,49.12 					"/>
					<rect x="49.765" y="49.485" style="fill:#4F4B49;" width="0.729" height="0.364"/>
					<rect x="50.858" y="49.485" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="50.494,50.578 51.223,50.578 51.223,50.943 51.587,50.943 51.587,50.578 51.587,50.214 
						50.494,50.214 					"/>
					<rect x="50.129" y="50.578" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="51.952,49.849 53.41,49.849 53.41,49.485 51.952,49.485 51.587,49.485 51.587,50.214 
						51.952,50.214 					"/>
					<rect x="51.952" y="50.578" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="53.41,50.943 52.681,50.943 52.681,51.307 53.774,51.307 53.774,50.943 53.774,50.578 
						53.41,50.578 					"/>
					<rect x="54.503" y="49.485" style="fill:#4F4B49;" width="0.729" height="0.364"/>
					<polygon style="fill:#4F4B49;" points="54.867,50.943 55.232,50.943 55.232,50.578 54.867,50.578 54.867,50.214 54.503,50.214 
						54.503,50.943 54.138,50.943 54.138,51.672 54.503,51.672 54.503,51.307 54.867,51.307 					"/>
					<polygon style="fill:#4F4B49;" points="54.867,51.672 54.867,53.129 55.232,53.129 55.232,52.036 55.596,52.036 55.596,51.672 
						55.232,51.672 					"/>
					<rect x="53.774" y="49.485" style="fill:#4F4B49;" width="0.364" height="1.093"/>
					<rect x="52.681" y="51.672" style="fill:#4F4B49;" width="0.364" height="0.729"/>
					<polygon style="fill:#4F4B49;" points="53.41,52.4 54.138,52.4 54.138,52.765 54.503,52.765 54.503,52.4 54.503,52.036 
						53.41,52.036 					"/>
					<rect x="51.952" y="51.672" style="fill:#4F4B49;" width="0.364" height="1.093"/>
					<rect x="52.681" y="52.765" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="52.681" y="50.214" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="51.952" y="48.027" style="fill:#4F4B49;" width="0.364" height="0.364"/>
					<rect x="51.952" y="47.298" style="fill:#4F4B49;" width="0.364" height="0.364"/>
				</g>
				<polygon style="fill:#F7B700;" points="50.129,46.205 48.672,46.205 48.672,47.663 49.036,47.663 49.036,46.569 50.129,46.569 
									"/>
				<polygon style="fill:#F7B700;" points="56.69,47.663 56.69,46.205 55.232,46.205 55.232,46.569 56.325,46.569 56.325,47.663 
									"/>
				<polygon style="fill:#F7B700;" points="55.232,54.223 56.69,54.223 56.69,52.765 56.325,52.765 56.325,53.858 55.232,53.858 
									"/>
				<polygon style="fill:#F7B700;" points="48.672,52.765 48.672,54.223 50.129,54.223 50.129,53.858 49.036,53.858 49.036,52.765 
									"/>
				<path style="fill:#B5B2B1;" d="M55.026,57.138h-4.691c-0.114,0-0.206-0.092-0.206-0.206v-0.317c0-0.114,0.092-0.206,0.206-0.206
					h4.691c0.114,0,0.206,0.092,0.206,0.206v0.317C55.232,57.046,55.14,57.138,55.026,57.138z"/>
				<path style="fill:#B5B2B1;" d="M54.297,58.596h-3.233c-0.114,0-0.206-0.092-0.206-0.206v-0.317c0-0.114,0.092-0.206,0.206-0.206
					h3.233c0.114,0,0.206,0.092,0.206,0.206v0.317C54.503,58.504,54.411,58.596,54.297,58.596z"/>
			</g>
		</g>
	</g>
	<g id="text">
		<path style="fill:#343434;" d="M71.357,47.344c-0.509,0-0.763,0.168-0.763,0.504c0,0.149,0.063,0.264,0.189,0.343
			c0.126,0.08,0.421,0.194,0.885,0.343c0.464,0.149,0.79,0.319,0.977,0.508c0.187,0.189,0.28,0.479,0.28,0.872
			c0,0.499-0.151,0.877-0.455,1.134c-0.304,0.257-0.7,0.385-1.19,0.385c-0.364,0-0.81-0.056-1.337-0.168l-0.252-0.049l0.098-0.749
			c0.625,0.084,1.104,0.126,1.435,0.126c0.495,0,0.742-0.205,0.742-0.616c0-0.149-0.057-0.266-0.171-0.35s-0.342-0.176-0.683-0.273
			c-0.541-0.154-0.923-0.337-1.145-0.55c-0.222-0.212-0.333-0.507-0.333-0.885c0-0.477,0.145-0.831,0.434-1.064
			c0.29-0.233,0.686-0.35,1.19-0.35c0.346,0,0.784,0.047,1.316,0.14l0.252,0.049l-0.077,0.764
			C72.092,47.381,71.628,47.344,71.357,47.344z"/>
		<path style="fill:#343434;" d="M74.928,47.764c0.242,0,0.553,0.037,0.931,0.112l0.189,0.042L76.02,48.66
			c-0.369-0.037-0.642-0.056-0.819-0.056c-0.322,0-0.536,0.069-0.641,0.207s-0.158,0.397-0.158,0.78s0.053,0.646,0.158,0.787
			c0.105,0.143,0.321,0.214,0.647,0.214l0.812-0.056l0.028,0.748c-0.499,0.099-0.879,0.147-1.141,0.147
			c-0.518,0-0.89-0.146-1.117-0.438s-0.339-0.76-0.339-1.403c0-0.645,0.117-1.109,0.35-1.396
			C74.034,47.908,74.41,47.764,74.928,47.764z"/>
		<path style="fill:#343434;" d="M79.421,48.94v1.456c0.009,0.112,0.028,0.19,0.056,0.234c0.028,0.045,0.086,0.076,0.175,0.095
			l-0.028,0.707c-0.238,0-0.428-0.017-0.57-0.049c-0.143-0.033-0.286-0.099-0.431-0.196c-0.336,0.163-0.679,0.245-1.029,0.245
			c-0.724,0-1.085-0.383-1.085-1.148c0-0.373,0.1-0.638,0.301-0.794s0.509-0.249,0.924-0.276l0.749-0.057V48.94
			c0-0.145-0.032-0.243-0.098-0.297s-0.17-0.081-0.315-0.081l-1.316,0.056l-0.028-0.65c0.5-0.136,0.974-0.203,1.425-0.203
			c0.45,0,0.774,0.094,0.973,0.28S79.421,48.53,79.421,48.94z M77.846,49.864c-0.261,0.023-0.392,0.164-0.392,0.42
			c0,0.257,0.114,0.386,0.343,0.386c0.177,0,0.371-0.028,0.581-0.084l0.105-0.028v-0.749L77.846,49.864z"/>
		<path style="fill:#343434;" d="M81.255,51.348h-0.938v-3.5h0.931v0.196c0.317-0.187,0.609-0.28,0.875-0.28
			c0.466,0,0.789,0.138,0.966,0.413s0.266,0.7,0.266,1.273v1.897h-0.938v-1.869c0-0.299-0.035-0.519-0.105-0.661
			c-0.07-0.143-0.21-0.214-0.42-0.214c-0.191,0-0.375,0.033-0.553,0.098l-0.084,0.028V51.348z"/>
		<path style="fill:#343434;" d="M85.728,47.4v-0.952h0.938V47.4H85.728z M85.728,51.348v-3.5h0.938v3.5H85.728z"/>
		<path style="fill:#343434;" d="M89.473,48.646h-0.861v1.436c0,0.14,0.003,0.239,0.01,0.297c0.007,0.059,0.033,0.108,0.077,0.15
			c0.044,0.042,0.113,0.063,0.207,0.063l0.532-0.014l0.042,0.748c-0.312,0.07-0.551,0.105-0.714,0.105
			c-0.42,0-0.707-0.095-0.861-0.283c-0.154-0.189-0.231-0.538-0.231-1.047v-1.456h-0.413v-0.798h0.413v-0.973h0.938v0.973h0.861
			V48.646z"/>
		<path style="fill:#343434;" d="M91.405,47.848h0.525l0.707,3.045h0.112l0.833-2.975h0.546l0.833,2.975h0.119l0.7-3.045h0.525
			l-0.833,3.5h-0.875l-0.742-2.737l-0.742,2.737h-0.875L91.405,47.848z"/>
		<path style="fill:#343434;" d="M97.026,47.057v-0.609h0.525v0.609H97.026z M97.026,51.348v-3.5h0.525v3.5H97.026z"/>
		<path style="fill:#343434;" d="M100.372,48.303h-1.113v1.673c0,0.401,0.029,0.665,0.087,0.791
			c0.058,0.126,0.197,0.189,0.417,0.189l0.623-0.042l0.035,0.434c-0.313,0.052-0.551,0.077-0.714,0.077
			c-0.364,0-0.616-0.089-0.756-0.267c-0.14-0.177-0.21-0.516-0.21-1.015v-1.841h-0.497v-0.455h0.497v-1.071h0.518v1.071h1.113
			V48.303z"/>
		<path style="fill:#343434;" d="M101.54,51.348h-0.525v-5.026h0.525v1.723c0.374-0.178,0.733-0.267,1.078-0.267
			c0.467,0,0.781,0.126,0.941,0.378c0.161,0.252,0.242,0.7,0.242,1.345v1.848h-0.525v-1.834c0-0.485-0.048-0.817-0.144-0.997
			s-0.297-0.27-0.605-0.27c-0.294,0-0.579,0.054-0.854,0.16l-0.133,0.05V51.348z"/>
		<path style="fill:#343434;" d="M107.624,47.778c0.154,0,0.416,0.028,0.784,0.084l0.168,0.021l-0.021,0.427
			c-0.374-0.042-0.649-0.062-0.826-0.062c-0.396,0-0.666,0.094-0.809,0.283c-0.142,0.188-0.213,0.539-0.213,1.05
			s0.066,0.867,0.2,1.067c0.133,0.201,0.409,0.301,0.829,0.301l0.826-0.062l0.021,0.434c-0.434,0.065-0.758,0.099-0.973,0.099
			c-0.546,0-0.923-0.141-1.13-0.42c-0.208-0.28-0.312-0.753-0.312-1.418s0.112-1.132,0.336-1.4
			C106.728,47.913,107.101,47.778,107.624,47.778z"/>
		<path style="fill:#343434;" d="M111.838,48.905v1.792c0.014,0.173,0.149,0.275,0.406,0.309l-0.021,0.413
			c-0.369,0-0.646-0.094-0.833-0.28c-0.42,0.187-0.84,0.28-1.26,0.28c-0.322,0-0.567-0.092-0.735-0.273
			c-0.168-0.182-0.252-0.443-0.252-0.784c0-0.34,0.086-0.591,0.259-0.752c0.172-0.161,0.443-0.261,0.812-0.298l1.099-0.104v-0.302
			c0-0.237-0.052-0.408-0.154-0.511s-0.243-0.154-0.42-0.154c-0.374,0-0.761,0.023-1.162,0.07l-0.217,0.021l-0.021-0.399
			c0.513-0.103,0.968-0.154,1.365-0.154c0.397,0,0.685,0.092,0.865,0.273S111.838,48.518,111.838,48.905z M109.682,50.334
			c0,0.429,0.177,0.644,0.532,0.644c0.317,0,0.63-0.054,0.938-0.161l0.161-0.056v-1.155l-1.036,0.099
			c-0.21,0.019-0.362,0.079-0.455,0.182S109.682,50.137,109.682,50.334z"/>
		<path style="fill:#343434;" d="M113.398,51.348h-0.525v-3.5h0.518v0.245c0.35-0.21,0.69-0.315,1.022-0.315
			c0.434,0,0.737,0.119,0.91,0.357c0.154-0.094,0.354-0.176,0.599-0.249c0.245-0.072,0.46-0.108,0.647-0.108
			c0.466,0,0.78,0.125,0.941,0.375s0.241,0.698,0.241,1.348v1.848h-0.525v-1.834c0-0.485-0.046-0.817-0.14-0.997
			c-0.093-0.18-0.292-0.27-0.595-0.27c-0.154,0-0.313,0.021-0.476,0.066c-0.164,0.044-0.29,0.087-0.378,0.129l-0.133,0.063
			c0.061,0.153,0.091,0.494,0.091,1.021v1.82h-0.525v-1.806c0-0.504-0.045-0.846-0.137-1.025c-0.091-0.18-0.291-0.27-0.599-0.27
			c-0.149,0-0.302,0.021-0.458,0.066c-0.156,0.044-0.276,0.087-0.36,0.129l-0.119,0.063V51.348z"/>
		<path style="fill:#343434;" d="M121.105,50.915l0.203-0.021l0.014,0.413c-0.532,0.074-0.987,0.112-1.365,0.112
			c-0.504,0-0.861-0.146-1.071-0.438c-0.21-0.292-0.315-0.746-0.315-1.361c0-1.228,0.487-1.842,1.463-1.842
			c0.471,0,0.824,0.132,1.057,0.396s0.35,0.678,0.35,1.243l-0.028,0.398h-2.31c0,0.388,0.07,0.675,0.21,0.861
			c0.14,0.187,0.384,0.28,0.732,0.28C120.393,50.957,120.746,50.942,121.105,50.915z M120.916,49.395
			c0-0.429-0.069-0.732-0.207-0.91c-0.138-0.177-0.361-0.266-0.672-0.266s-0.543,0.094-0.7,0.28
			c-0.157,0.187-0.237,0.485-0.242,0.896H120.916z"/>
		<path style="fill:#343434;" d="M122.26,51.348v-3.5h0.518v0.476c0.406-0.279,0.833-0.464,1.281-0.553v0.532
			c-0.196,0.037-0.4,0.096-0.613,0.175s-0.375,0.149-0.486,0.21l-0.175,0.091v2.569H122.26z"/>
		<path style="fill:#343434;" d="M127.153,48.905v1.792c0.014,0.173,0.149,0.275,0.406,0.309l-0.021,0.413
			c-0.369,0-0.646-0.094-0.833-0.28c-0.42,0.187-0.84,0.28-1.26,0.28c-0.322,0-0.567-0.092-0.735-0.273
			c-0.168-0.182-0.252-0.443-0.252-0.784c0-0.34,0.086-0.591,0.259-0.752c0.172-0.161,0.443-0.261,0.812-0.298l1.099-0.104v-0.302
			c0-0.237-0.052-0.408-0.154-0.511s-0.243-0.154-0.42-0.154c-0.374,0-0.761,0.023-1.162,0.07l-0.217,0.021l-0.021-0.399
			c0.513-0.103,0.968-0.154,1.365-0.154c0.397,0,0.685,0.092,0.865,0.273S127.153,48.518,127.153,48.905z M124.997,50.334
			c0,0.429,0.177,0.644,0.532,0.644c0.317,0,0.63-0.054,0.938-0.161l0.161-0.056v-1.155l-1.036,0.099
			c-0.21,0.019-0.362,0.079-0.455,0.182S124.997,50.137,124.997,50.334z"/>
		<path style="fill:#343434;" d="M72.1,57.992c0-0.64,0.114-1.102,0.343-1.386c0.229-0.285,0.615-0.428,1.159-0.428
			c0.543,0,0.929,0.143,1.155,0.428c0.227,0.284,0.34,0.746,0.34,1.386s-0.107,1.104-0.322,1.393
			c-0.215,0.29-0.606,0.435-1.176,0.435s-0.961-0.145-1.176-0.435C72.207,59.095,72.1,58.631,72.1,57.992z M72.639,57.981
			c0,0.511,0.062,0.869,0.186,1.074c0.124,0.206,0.382,0.309,0.777,0.309c0.394,0,0.652-0.102,0.773-0.305
			c0.121-0.203,0.182-0.562,0.182-1.074c0-0.514-0.068-0.867-0.203-1.061c-0.135-0.194-0.386-0.291-0.752-0.291
			c-0.367,0-0.619,0.097-0.756,0.291C72.707,57.118,72.639,57.47,72.639,57.981z"/>
		<path style="fill:#343434;" d="M75.949,59.749v-3.5h0.518v0.476c0.406-0.279,0.833-0.464,1.281-0.553v0.532
			c-0.196,0.037-0.4,0.096-0.613,0.175s-0.375,0.149-0.486,0.21l-0.175,0.091v2.569H75.949z"/>
		<path style="fill:#343434;" d="M82.382,57.305v1.792c0.014,0.173,0.149,0.275,0.406,0.309l-0.021,0.413
			c-0.369,0-0.646-0.094-0.833-0.28c-0.42,0.187-0.84,0.28-1.26,0.28c-0.322,0-0.567-0.092-0.735-0.273
			c-0.168-0.182-0.252-0.443-0.252-0.784c0-0.34,0.086-0.591,0.259-0.752c0.172-0.161,0.443-0.261,0.812-0.298l1.099-0.104v-0.302
			c0-0.237-0.052-0.408-0.154-0.511s-0.243-0.154-0.42-0.154c-0.374,0-0.761,0.023-1.162,0.07l-0.217,0.021l-0.021-0.399
			c0.513-0.103,0.968-0.154,1.365-0.154c0.397,0,0.685,0.092,0.865,0.273S82.382,56.919,82.382,57.305z M80.226,58.734
			c0,0.429,0.177,0.644,0.532,0.644c0.317,0,0.63-0.054,0.938-0.161l0.161-0.056v-1.155l-1.036,0.099
			c-0.21,0.019-0.362,0.079-0.455,0.182S80.226,58.538,80.226,58.734z"/>
		<path style="fill:#343434;" d="M83.943,59.749h-0.525v-3.5h0.518v0.245c0.378-0.21,0.74-0.315,1.085-0.315
			c0.467,0,0.781,0.126,0.941,0.378c0.161,0.252,0.242,0.7,0.242,1.345v1.848h-0.518v-1.834c0-0.485-0.048-0.817-0.144-0.997
			s-0.3-0.27-0.612-0.27c-0.149,0-0.307,0.021-0.473,0.066c-0.166,0.044-0.293,0.087-0.381,0.129l-0.133,0.063V59.749z"/>
		<path style="fill:#343434;" d="M86.806,56.249h0.525l0.875,3.045h0.231l0.882-3.045h0.525l-1.456,5.054h-0.525l0.455-1.554H87.8
			L86.806,56.249z"/>
		<path style="fill:#343434;" d="M93.862,59.819c-0.724,0-1.223-0.198-1.498-0.596c-0.275-0.396-0.413-1.021-0.413-1.872
			s0.14-1.484,0.42-1.897c0.28-0.412,0.777-0.619,1.491-0.619s1.212,0.205,1.495,0.616c0.282,0.41,0.423,1.043,0.423,1.896
			c0,0.593-0.061,1.067-0.182,1.425c-0.121,0.357-0.329,0.622-0.623,0.795l0.595,0.959l-0.504,0.237l-0.616-1.008
			C94.296,59.797,94.1,59.819,93.862,59.819z M92.504,57.347c0,0.71,0.093,1.22,0.28,1.53c0.187,0.31,0.547,0.465,1.082,0.465
			s0.894-0.15,1.078-0.451c0.184-0.301,0.276-0.81,0.276-1.526c0-0.716-0.096-1.237-0.287-1.564c-0.191-0.326-0.549-0.49-1.074-0.49
			s-0.882,0.163-1.071,0.487S92.504,56.638,92.504,57.347z"/>
		<path style="fill:#343434;" d="M97.306,57.893v1.855h-0.539v-4.844h1.792c0.513,0,0.899,0.119,1.158,0.356
			c0.259,0.238,0.389,0.609,0.389,1.113c0,0.747-0.296,1.216-0.889,1.407l0.931,1.967h-0.595l-0.875-1.855H97.306z M98.566,57.418
			c0.658,0,0.987-0.34,0.987-1.019s-0.331-1.019-0.994-1.019h-1.253v2.037H98.566z"/>
		<path style="fill:#343434;" d="M103.627,56.648c-0.509,0-0.763,0.177-0.763,0.531c0,0.164,0.059,0.279,0.175,0.347
			s0.382,0.138,0.798,0.21s0.709,0.174,0.882,0.305c0.173,0.131,0.259,0.376,0.259,0.735s-0.116,0.623-0.347,0.791
			s-0.568,0.252-1.011,0.252c-0.29,0-0.63-0.033-1.022-0.099l-0.21-0.035l0.028-0.454c0.532,0.079,0.933,0.118,1.204,0.118
			c0.271,0,0.477-0.043,0.619-0.129c0.143-0.087,0.214-0.231,0.214-0.435c0-0.202-0.061-0.34-0.182-0.413
			c-0.122-0.072-0.388-0.141-0.798-0.206c-0.411-0.065-0.703-0.161-0.875-0.287c-0.173-0.126-0.259-0.359-0.259-0.7
			c0-0.34,0.12-0.592,0.36-0.756c0.241-0.163,0.541-0.245,0.9-0.245c0.284,0,0.642,0.033,1.071,0.099l0.196,0.035l-0.014,0.447
			C104.334,56.685,103.925,56.648,103.627,56.648z"/>
		<path style="fill:#343434;" d="M107.092,56.178c0.154,0,0.416,0.028,0.784,0.084l0.168,0.021l-0.021,0.427
			c-0.374-0.042-0.649-0.062-0.826-0.062c-0.396,0-0.666,0.094-0.809,0.283c-0.142,0.188-0.213,0.539-0.213,1.05
			s0.066,0.867,0.2,1.067c0.133,0.201,0.409,0.301,0.829,0.301l0.826-0.062l0.021,0.434c-0.434,0.065-0.758,0.099-0.973,0.099
			c-0.546,0-0.923-0.141-1.13-0.42c-0.208-0.28-0.312-0.753-0.312-1.418s0.112-1.132,0.336-1.4
			C106.196,56.313,106.569,56.178,107.092,56.178z"/>
		<path style="fill:#343434;" d="M111.306,57.305v1.792c0.014,0.173,0.149,0.275,0.406,0.309l-0.021,0.413
			c-0.369,0-0.646-0.094-0.833-0.28c-0.42,0.187-0.84,0.28-1.26,0.28c-0.322,0-0.567-0.092-0.735-0.273
			c-0.168-0.182-0.252-0.443-0.252-0.784c0-0.34,0.086-0.591,0.259-0.752c0.172-0.161,0.443-0.261,0.812-0.298l1.099-0.104v-0.302
			c0-0.237-0.052-0.408-0.154-0.511s-0.243-0.154-0.42-0.154c-0.374,0-0.761,0.023-1.162,0.07l-0.217,0.021l-0.021-0.399
			c0.513-0.103,0.968-0.154,1.365-0.154c0.397,0,0.685,0.092,0.865,0.273S111.306,56.919,111.306,57.305z M109.149,58.734
			c0,0.429,0.177,0.644,0.532,0.644c0.317,0,0.63-0.054,0.938-0.161l0.161-0.056v-1.155l-1.036,0.099
			c-0.21,0.019-0.362,0.079-0.455,0.182S109.149,58.538,109.149,58.734z"/>
		<path style="fill:#343434;" d="M112.867,59.749h-0.525v-3.5h0.518v0.245c0.378-0.21,0.74-0.315,1.085-0.315
			c0.467,0,0.781,0.126,0.941,0.378c0.161,0.252,0.242,0.7,0.242,1.345v1.848h-0.518v-1.834c0-0.485-0.048-0.817-0.144-0.997
			s-0.3-0.27-0.612-0.27c-0.149,0-0.307,0.021-0.473,0.066c-0.166,0.044-0.293,0.087-0.381,0.129l-0.133,0.063V59.749z"/>
		<path style="fill:#343434;" d="M116.625,59.749h-0.525v-3.5h0.518v0.245c0.378-0.21,0.74-0.315,1.085-0.315
			c0.467,0,0.781,0.126,0.941,0.378c0.161,0.252,0.242,0.7,0.242,1.345v1.848h-0.518v-1.834c0-0.485-0.048-0.817-0.144-0.997
			s-0.3-0.27-0.612-0.27c-0.149,0-0.307,0.021-0.473,0.066c-0.166,0.044-0.293,0.087-0.381,0.129l-0.133,0.063V59.749z"/>
		<path style="fill:#343434;" d="M122.239,59.315l0.203-0.021l0.014,0.413c-0.532,0.074-0.987,0.112-1.365,0.112
			c-0.504,0-0.861-0.146-1.071-0.438c-0.21-0.292-0.315-0.746-0.315-1.361c0-1.228,0.487-1.842,1.463-1.842
			c0.471,0,0.824,0.132,1.057,0.396s0.35,0.678,0.35,1.243l-0.028,0.398h-2.31c0,0.388,0.07,0.675,0.21,0.861
			c0.14,0.187,0.384,0.28,0.732,0.28C121.526,59.357,121.88,59.342,122.239,59.315z M122.05,57.795c0-0.429-0.069-0.732-0.207-0.91
			c-0.138-0.177-0.361-0.266-0.672-0.266s-0.543,0.094-0.7,0.28c-0.157,0.187-0.237,0.485-0.242,0.896H122.05z"/>
		<path style="fill:#343434;" d="M123.395,59.749v-3.5h0.518v0.476c0.406-0.279,0.833-0.464,1.281-0.553v0.532
			c-0.196,0.037-0.4,0.096-0.613,0.175s-0.375,0.149-0.486,0.21l-0.175,0.091v2.569H123.395z"/>
	</g>
</g>
<g id="title">
	<path style="fill:#343434;" d="M50.944,16.279c0.181,0,0.316,0.006,0.406,0.017c0.09,0.012,0.192,0.05,0.306,0.112
		c0.114,0.063,0.194,0.169,0.241,0.318s0.071,0.349,0.071,0.6c0,0.251-0.024,0.451-0.071,0.599
		c-0.047,0.149-0.129,0.253-0.247,0.312c-0.118,0.058-0.222,0.094-0.312,0.105c-0.09,0.012-0.23,0.018-0.418,0.018h-3.6v1.188h2.318
		c0.189,0,0.327,0.006,0.418,0.017c0.09,0.012,0.194,0.05,0.312,0.112c0.204,0.118,0.306,0.428,0.306,0.93
		c0,0.549-0.149,0.874-0.447,0.977c-0.125,0.039-0.326,0.058-0.6,0.058H47.32v2.224c0,0.188-0.006,0.326-0.018,0.412
		c-0.011,0.086-0.049,0.188-0.112,0.305c-0.11,0.212-0.416,0.318-0.918,0.318c-0.549,0-0.871-0.149-0.965-0.447
		c-0.047-0.125-0.07-0.326-0.07-0.6v-6.542c0-0.4,0.075-0.672,0.224-0.818c0.149-0.145,0.431-0.217,0.847-0.217H50.944z"/>
	<path style="fill:#343434;" d="M59.334,23.445c0.118,0.282,0.177,0.486,0.177,0.612c0,0.299-0.243,0.55-0.73,0.754
		c-0.251,0.109-0.453,0.165-0.606,0.165c-0.153,0-0.278-0.037-0.376-0.112c-0.098-0.075-0.17-0.151-0.218-0.23
		c-0.079-0.148-0.384-0.859-0.918-2.13l-0.364,0.024h-1.483v1.329c0,0.181-0.006,0.316-0.018,0.406
		c-0.011,0.091-0.049,0.194-0.112,0.312c-0.11,0.212-0.416,0.318-0.918,0.318c-0.549,0-0.871-0.146-0.965-0.436
		c-0.047-0.133-0.07-0.337-0.07-0.612v-6.531c0-0.18,0.006-0.315,0.018-0.406c0.011-0.09,0.049-0.193,0.112-0.311
		c0.11-0.213,0.416-0.318,0.918-0.318h2.542c0.69,0,1.361,0.251,2.012,0.753c0.314,0.243,0.573,0.573,0.777,0.989
		c0.204,0.416,0.306,0.878,0.306,1.388c0,0.886-0.294,1.615-0.882,2.188C58.706,22.013,58.973,22.629,59.334,23.445z M54.815,20.445
		h1.506c0.227,0,0.455-0.086,0.682-0.259c0.227-0.173,0.341-0.432,0.341-0.777c0-0.346-0.114-0.606-0.341-0.783
		c-0.227-0.176-0.463-0.264-0.706-0.264h-1.483V20.445z"/>
	<path style="fill:#343434;" d="M62.24,22.821h3.624c0.18,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112
		c0.212,0.11,0.317,0.416,0.317,0.918c0,0.549-0.145,0.87-0.435,0.964c-0.134,0.047-0.338,0.071-0.612,0.071h-4.66
		c-0.549,0-0.871-0.149-0.965-0.447c-0.047-0.125-0.07-0.326-0.07-0.6v-6.542c0-0.4,0.075-0.672,0.224-0.818
		c0.149-0.145,0.431-0.217,0.847-0.217h4.636c0.18,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112
		c0.212,0.11,0.317,0.416,0.317,0.918c0,0.549-0.145,0.87-0.435,0.964c-0.134,0.047-0.338,0.071-0.612,0.071H62.24v1.188h2.33
		c0.181,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112c0.212,0.11,0.318,0.416,0.318,0.918
		c0,0.549-0.149,0.87-0.447,0.964c-0.134,0.047-0.338,0.071-0.612,0.071H62.24V22.821z"/>
	<path style="fill:#343434;" d="M69.747,22.821h3.624c0.18,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112
		c0.212,0.11,0.317,0.416,0.317,0.918c0,0.549-0.145,0.87-0.435,0.964c-0.134,0.047-0.338,0.071-0.612,0.071H68.7
		c-0.549,0-0.871-0.149-0.965-0.447c-0.047-0.125-0.07-0.326-0.07-0.6v-6.542c0-0.4,0.075-0.672,0.224-0.818
		c0.149-0.145,0.431-0.217,0.847-0.217h4.636c0.18,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112
		c0.212,0.11,0.317,0.416,0.317,0.918c0,0.549-0.145,0.87-0.435,0.964c-0.134,0.047-0.338,0.071-0.612,0.071h-3.612v1.188h2.33
		c0.181,0,0.316,0.006,0.406,0.017c0.09,0.012,0.194,0.05,0.312,0.112c0.212,0.11,0.318,0.416,0.318,0.918
		c0,0.549-0.149,0.87-0.447,0.964c-0.134,0.047-0.338,0.071-0.612,0.071h-2.306V22.821z"/>
	<path style="fill:#343434;" d="M88.467,16.326c0.526,0.189,0.789,0.44,0.789,0.753c0,0.102-0.047,0.295-0.141,0.577l-2.259,6.53
		c-0.016,0.056-0.043,0.123-0.082,0.2c-0.039,0.079-0.143,0.183-0.312,0.312c-0.169,0.129-0.369,0.194-0.6,0.194
		c-0.232,0-0.432-0.065-0.6-0.194c-0.169-0.129-0.281-0.257-0.335-0.382l-1.377-4.06c-0.87,2.573-1.314,3.883-1.33,3.93
		c-0.016,0.048-0.051,0.114-0.106,0.2c-0.055,0.087-0.118,0.165-0.188,0.236c-0.18,0.181-0.396,0.271-0.647,0.271
		c-0.251,0-0.457-0.059-0.618-0.177c-0.161-0.117-0.269-0.235-0.324-0.352l-0.082-0.177l-2.271-6.53
		c-0.087-0.26-0.129-0.451-0.129-0.577c0-0.313,0.262-0.56,0.788-0.741c0.227-0.078,0.433-0.118,0.618-0.118
		s0.326,0.063,0.424,0.189s0.186,0.318,0.265,0.576l1.295,3.93l1.282-3.87c0.11-0.329,0.31-0.557,0.6-0.683
		c0.125-0.055,0.288-0.082,0.488-0.082c0.2,0,0.386,0.064,0.559,0.194c0.172,0.129,0.282,0.257,0.329,0.382l1.353,4.083l1.295-3.954
		c0.063-0.18,0.113-0.313,0.153-0.4c0.039-0.086,0.112-0.17,0.218-0.253c0.106-0.082,0.237-0.124,0.394-0.124
		C88.071,16.208,88.256,16.247,88.467,16.326z"/>
	<path style="fill:#343434;" d="M89.903,17.314c0-0.18,0.006-0.315,0.018-0.406c0.011-0.09,0.049-0.193,0.112-0.311
		c0.11-0.213,0.416-0.318,0.918-0.318c0.549,0,0.875,0.146,0.977,0.436c0.039,0.133,0.059,0.337,0.059,0.612v6.542
		c0,0.188-0.006,0.326-0.018,0.412c-0.011,0.086-0.049,0.188-0.112,0.305c-0.11,0.212-0.416,0.318-0.918,0.318
		c-0.549,0-0.871-0.149-0.965-0.447c-0.047-0.125-0.07-0.326-0.07-0.6V17.314z"/>
	<path style="fill:#343434;" d="M96.398,23.268h-2.8c-0.44,0-0.702-0.133-0.789-0.4c-0.039-0.141-0.059-0.322-0.059-0.541
		c0-0.219,0.024-0.396,0.071-0.53s0.122-0.228,0.224-0.282c0.141-0.071,0.329-0.106,0.564-0.106h2.801
		c0.439,0,0.698,0.13,0.777,0.389c0.047,0.141,0.07,0.322,0.07,0.541c0,0.22-0.022,0.398-0.065,0.535
		c-0.043,0.138-0.112,0.234-0.206,0.288C96.845,23.233,96.649,23.268,96.398,23.268z"/>
	<path style="fill:#343434;" d="M103.728,16.279c0.18,0,0.316,0.006,0.406,0.017c0.09,0.012,0.192,0.05,0.306,0.112
		c0.114,0.063,0.194,0.169,0.241,0.318c0.047,0.149,0.071,0.349,0.071,0.6c0,0.251-0.024,0.451-0.071,0.599
		c-0.047,0.149-0.129,0.253-0.247,0.312c-0.118,0.058-0.222,0.094-0.312,0.105c-0.09,0.012-0.23,0.018-0.418,0.018h-3.6v1.188h2.318
		c0.189,0,0.327,0.006,0.418,0.017c0.09,0.012,0.194,0.05,0.312,0.112c0.204,0.118,0.306,0.428,0.306,0.93
		c0,0.549-0.149,0.874-0.447,0.977c-0.125,0.039-0.326,0.058-0.6,0.058h-2.306v2.224c0,0.188-0.006,0.326-0.018,0.412
		c-0.011,0.086-0.049,0.188-0.112,0.305c-0.11,0.212-0.416,0.318-0.918,0.318c-0.549,0-0.871-0.149-0.965-0.447
		c-0.047-0.125-0.07-0.326-0.07-0.6v-6.542c0-0.4,0.075-0.672,0.224-0.818c0.149-0.145,0.431-0.217,0.847-0.217H103.728z"/>
	<path style="fill:#343434;" d="M105.517,17.314c0-0.18,0.006-0.315,0.018-0.406c0.011-0.09,0.049-0.193,0.112-0.311
		c0.11-0.213,0.416-0.318,0.918-0.318c0.549,0,0.875,0.146,0.977,0.436c0.039,0.133,0.059,0.337,0.059,0.612v6.542
		c0,0.188-0.006,0.326-0.018,0.412c-0.011,0.086-0.049,0.188-0.112,0.305c-0.11,0.212-0.416,0.318-0.918,0.318
		c-0.549,0-0.871-0.149-0.965-0.447c-0.047-0.125-0.07-0.326-0.07-0.6V17.314z"/>
</g>
`
        + networkSvg
        + (password ? passwordSvg : '')
        + `</svg>`
}
