import React from 'react'
import Popup from '../popup/Popup'
import Emoji from "../emoji/Emoji";

const PrivacyDialog = (props) => {
    const textContainerS = (isDesktop) => {
        return {
            marginLeft: isDesktop ? '24px' : '16px',
            marginRight: isDesktop ? '24px' : '16px',
            marginBottom: '0',
            fontFamily: 'TitilliumWeb-Regular',
            color: '#333333',
            paddingBottom: '8px'
        }
    }

    const mainTitleS = (isDesktop) => {
        return {
            paddingTop: isDesktop ? '64px' : '48px',
            paddingBottom: '24px',
            fontFamily: 'FredokaOne-Regular',
            fontSize: '24px',
            textAlign: 'center',
            color: '#333333'
        }
    }

    const titleS = {
        fontFamily: 'TitilliumWeb-Bold',
        fontSize: '16px',
        paddingTop: '40px',
        paddingBottom: '16px'
    }


    const dialogContent = (widthIsMoreThanThreshold, heightIsMoreThanThreshold) =>
        <div style={textContainerS(widthIsMoreThanThreshold)}>
            <div style={mainTitleS(widthIsMoreThanThreshold)}>It is safe!</div>

            <EmojiListItem emojiType='thumbs-up-sign'
                           text='Waflee does not transfer your data. Even to its own servers!'/>
            <EmojiListItem emojiType='see-no-evil-monkey'
                           text='Waflee uses analytics to improve the product. It does not track any personal data and your input.'/>
            <EmojiListItem emojiType='cookie'
                           text='Waflee does not use Cookies.'/>

            <div style={titleS}>Privacy policy</div>
            <p>At waflee, accessible from waflee.app, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by waflee and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to <a href="mailto:wafleeqr@gmail.com" target="_blank" rel="noopener noreferrer">contact us</a>.</p>

            <p>Waflee follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

            <div style={titleS}>Consent</div>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
        </div>

    return (
        <Popup onClose={props.onClose}
               widthAdaptiveThreshold={460}
               heightAdaptiveThreshold={985}
               content={dialogContent}>
        </Popup>
    )
}


const EmojiListItem = (props) => {
    const emojiListItemS = {
        display: 'flex',
        marginTop: '20px'
    }

    const emojiContainerS = {
        width: '24px',
        marginRight: '16px',
        height: '100%'
    }

    const emojiListItemTextS = {
    }

    return (
        <div style={emojiListItemS}>
            <div style={emojiContainerS}>
                <Emoji type={props.emojiType}/>
            </div>
            <div style={emojiListItemTextS}>{props.text}</div>
        </div>
    )
}
export default PrivacyDialog