import React from 'react';
import ToggleBtn from "../../toggle-btn/ToggleBtn";

const SecTypeSelector = (props) => {
    const s = {
        fontFamily: 'TitilliumWeb-Regular',
        fontSize: '16px',
        color: '#fff',
        marginBottom: '8px'
    }

    return (
        <>
            <div style={s}>Type of security</div>
            <ToggleBtn
                buttons={[
                    { value: 'WPA', label: 'WPA', enabled: true },
                    { value: 'WEP', label: 'WEP', enabled: true }
                ]}
                currentValue={props.value}
                onChange={props.onChange}
            />
        </>
    );
}

export default SecTypeSelector