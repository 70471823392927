import React, {useContext, useState} from 'react';
import { downloadPdf } from '../qrcode/wifiQrUtils';
import buildPosterProduct from '../products/poster/posterProductBuilder';
import PosterProductPreview from '../products/poster/PosterProductPreview';
import {trackEvent} from "../analytics/Analytics";
import CloseBtn from "./parts/close-btn/CloseBtn";
import {useMediaQuery} from "../utils";
import BottomSheet from "./parts/bottom-sheet/BottomSheet";
import layoutPreviewDialogStyle from "./layoutPreviewDialogStyle";
import Overlay from "../overlay/Overlay";
import CardProductPreview from "../products/card/CardProductPreview";
import buildCardProduct from "../products/card/cardProductBuilder";
import FeedbackDialog from "./parts/feedback-dialog/FeedbackDialog";
import AppConfig from "../app-config/AppConfig";
import VirtualProductPreview from "../products/virtual/VirtualProductPreview";

const LayoutPreviewDialog = (props) => {
    const config = useContext(AppConfig)
    const isDesktop = useMediaQuery('(min-width: 620px)')
    const [selectedProduct, setSelectedProduct] = useState(isDesktop ? 'poster' : 'virtual')
    const [isCustomizationOpen, setIsCustomizationOpen] = useState(false)
    const [feedbackDialogShown, setFeedbackDialogShown] = useState(false)

    const s = layoutPreviewDialogStyle(isDesktop)

    const onGetBtnClick = () => {
        if (selectedProduct === 'poster') {
            trackEvent('poster-pdf-downloaded')
            downloadPdf(buildPosterProduct(props.network, props.password, props.qrCodeSvgStr), 'A4', 'waflee-poster.pdf');
        }
        else if (selectedProduct === 'card') {
            trackEvent('card-pdf-downloaded')
            downloadPdf(buildCardProduct(props.network, props.password, props.qrCodeSvgStr), 'business-card', 'waflee-card.pdf');
        }
        else {
            trackEvent('virtual-card-link-got')
            const params = {
                net: props.network,
                pass: props.password,
                sec: props.protection
            }
            const urlParams = JSON.stringify(params)
            window.open(config.virtualCardBaseUrl + '#' + urlParams, '_blank');
        }

        if (config.feedbackDialog.shouldShow) {
            setFeedbackDialogShown(true)
            if (config.feedbackDialog.showJustOnce) {
                config.feedbackDialog.shouldShow = false
            }
        }

    }

    const onCloseBtnClick = () => {
        trackEvent('layout-preview-closed')
        props.onClose()
    }

    const onBottomSheetToggle = () => {
        trackEvent('customization-block-' + (isCustomizationOpen ? 'closed' : 'opened'))
        setIsCustomizationOpen(!isCustomizationOpen)
    }

    const onProductChange = (product) => {
        trackEvent('product-selected-' + product)
        setSelectedProduct(product)
    }

    const title = selectedProduct === 'poster'
        ? 'Poster layout preview'
        : selectedProduct === 'card'
            ? 'Card layout preview'
            : 'Virtual card preview'
    return <div className="main-background" style={s.dialog}>
        <div style={s.contentContainer}>
            <CloseBtn isDesktop={isDesktop}
                      onClick={onCloseBtnClick}/>

            <div style={s.content}>
                <div style={s.header}>{title}</div>
                <div style={s.explanation}>Check the QR code first. If the QR code does not work, then check the correctness of the data you entered or try changing the type of security in the extended options</div>

                <div style={s.previewContainer}>
                    {
                        selectedProduct === 'poster' ?
                            <PosterProductPreview
                                isDesktop={isDesktop}
                                network={props.network}
                                password={props.password}
                                qrCodeSvgStr={props.qrCodeSvgStr}/>
                        : selectedProduct === 'card' ?
                            <CardProductPreview
                                isDesktop={isDesktop}
                                network={props.network}
                                password={props.password}
                                qrCodeSvgStr={props.qrCodeSvgStr}/>
                        :
                            <VirtualProductPreview
                                isDesktop={isDesktop}
                                network={props.network}
                                password={props.password}
                                qrCodeSvgStr={props.qrCodeSvgStr}/>
                    }
                </div>
            </div>
        </div>

        { isCustomizationOpen && <Overlay onClick={onBottomSheetToggle}/> }
        <BottomSheet isDesktop={isDesktop}
                     selectedProduct={selectedProduct}
                     onSelectedProductChange={onProductChange}
                     onGetBtnClick={onGetBtnClick}
                     isOpen={isCustomizationOpen}
                     onToggle={onBottomSheetToggle}/>

        { feedbackDialogShown && <FeedbackDialog onClose={() => setFeedbackDialogShown(false)}/> }
    </div>
}


export default LayoutPreviewDialog