export default function explanationTipStyle(isDesktop, imageUrl, imageWidth, imageHeight, bottom, left, textMarginTop) {
    const width = 360;
    return {
        container: {
            width: isDesktop ? width + 'px' : '100%',
            height: '96px',
            borderRadius: '10px',
            boxShadow: isDesktop ? '0 2px 20px 0 rgba(0, 0, 0, 0.15)' : null,
            backgroundColor: isDesktop ? '#343c50' : null,
            position: isDesktop ? 'fixed' : null,
            bottom: isDesktop ? bottom + 'px' : null,
            left: isDesktop ? left + 'px' : null,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: isDesktop ? '24px' : null,
            paddingRight: isDesktop ? '24px' : null,
            alignItems: isDesktop ? null : 'center'
        },
        image: {
            backgroundImage: 'url(' + imageUrl + ')',
            width: imageWidth + 'px',
            height: imageHeight + 'px',
            marginTop: '20px',
            marginRight: '24px',
        },
        text: {
            fontFamily: 'TitilliumWeb-Regular',
            fontSize: '16px',
            color: '#ffffff',
            marginTop: textMarginTop,
            flexBasis: '0',
            flexGrow: '1',
            width: isDesktop ? (width - imageWidth - 24 - 24 - 24) + 'px' : '100%'
        }
    }
}
