import React from "react";
import explanationTipStyle from "./explanationTipStyle";

export default function ExplanationTip(props) {
    const s = explanationTipStyle(
        props.isDesktop,
        props.imageUrl,
        props.imageWidth,
        props.imageHeight,
        props.bottom,
        props.left,
        props.textMarginTop
    )

    return (
        <div style={s.container}>
            <div style={s.image}/>
            <div style={s.text}>
                { props.children }
            </div>
        </div>
    )
}
