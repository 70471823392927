import { qrcode } from './qrcode-lib/qrcode';
import jsPDF from 'jspdf-yworks';
import svg2pdf from 'svg2pdf.js';
import { callAddFont as TitilliumWeb_Regular } from '../font/TitilliumWeb-Regular-normal';
import { callAddFont as TitilliumWeb_Bold } from '../font/TitilliumWeb-Bold-normal';


function escapeQrWifiSpecialChars(str) {
    return str.split("\\").join("\\\\")
        .split(";").join("\\;")
        .split(",").join("\\,")
        .split(":").join("\\:");
}

/*
 * "data" example:
 *  {
 *      network: "NetworkName",
 *      password: "some_password", // if password is used
 *      protection: "WPA" // WPA|WEP|nopass
 *  }
 * 
 * returns link for QR code as string
 */
function prepareWifiLink(data) {
    let result = 'WIFI:';

    if (data.protection !== 'nopass') {
        result += 'T:' + data.protection + ';';
        result += 'P:' + escapeQrWifiSpecialChars(data.password) + ';';
    }
    
    result += 'S:' + escapeQrWifiSpecialChars(data.network) + ';';
    result += ';';
    return result
}

/*
 * returns QR code svg as string
 */
function drawQrCode(wifiLink) {
    const typeNumber = 0; // todo comment it
    const errorCorrectionLevel = 'H'; // todo comment it

    const qr = qrcode(typeNumber, errorCorrectionLevel);
    qr.addData(wifiLink, 'Byte');
    qr.make();
    return qr.createSvgTag({
        margin: 0,
        fillColor: '#333333'
    });
}

/*
 * "data" example:
 *  {
 *      network: "NetworkName",
 *      password: "some_password", // if password is used
 *      protection: "WPA" // WPA|WEP|nopass
 *  }
 * 
 * returns qr code svg as string
 */
export function buildQrCode(data) {
    const link = prepareWifiLink(data);
    const qrCodeSvg = drawQrCode(link);
    return qrCodeSvg;
}

function createElementsFromStr(str) {
    // is there a better way to do it?
    const container = document.createElement("div");
    container.innerHTML = str;
    return container.firstElementChild;
}

export function downloadPdf(svgStr, format, filename) {
    const svgElement = createElementsFromStr(svgStr);

    jsPDF.API.events.push(['addFonts', TitilliumWeb_Regular])
    jsPDF.API.events.push(['addFonts', TitilliumWeb_Bold])

    const compensationCoef = 0.75; // because jsPDF makes it bigger somehow
    const a4Format = [595.28 * compensationCoef, 841.89 * compensationCoef]; // 72ppi size of Business card 55x85

    const businessCardAdditionalFieldsCoef = 1.06; // it should be a little bit bigger than 55x85 for printing
    const businessCardFormat = [
        155.906 * compensationCoef * businessCardAdditionalFieldsCoef,
        240.945 * compensationCoef * businessCardAdditionalFieldsCoef]; // 72ppi size of A4

    const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: format === 'A4' ? a4Format : businessCardFormat
    });

    svg2pdf(
        svgElement,
        pdf,
        {
            xOffset: 0,
            yOffset: 0,
            scale: compensationCoef * businessCardAdditionalFieldsCoef
        }
    );

    pdf.save(filename);
}