import React, {useState} from 'react';
import bottomSheetStyle from "./bottomSheetStyle";
import ToggleBtn from "../../../toggle-btn/ToggleBtn";
import ExplanationTip from "../explanation-tip/ExplanationTip";

const noop = () => {}

const BottomSheet = (props) => {
    const s = bottomSheetStyle(props.isDesktop, props.isOpen)
    const [popup, setPopup] = useState(null)

    const onPosterBtnMouseEnter = () => setPopup(posterExplanationTip(true))
    const onPosterBtnMouseLeave = () => setPopup(null)
    const onCardBtnMouseEnter = () => setPopup(cardExplanationTip(true))
    const onCardBtnMouseLeave = () => setPopup(null)
    const onVirtualBtnMouseEnter = () => setPopup(virtualExplanationTip(true))
    const onVirtualBtnMouseLeave = () => setPopup(null)

    return <div style={s.sheet}>
        { popup }
        <div style={s.bar}>
            { props.isDesktop
                ? productSelector(
                    props,
                    s,
                    props.isDesktop,
                    onPosterBtnMouseEnter,
                    onPosterBtnMouseLeave,
                    onCardBtnMouseEnter,
                    onCardBtnMouseLeave,
                    onVirtualBtnMouseEnter,
                    onVirtualBtnMouseLeave
                  )
                : customizeBtn(props, s)
            }

            <div className="waflee-btn primary-waflee-btn"
                 style={s.getBtn}
                 onClick={props.onGetBtnClick}>
                { props.selectedProduct === 'virtual' ? 'Get link' : 'Download' }
            </div>
        </div>

        { props.isDesktop ? '' : customizationBlock(props, s) }
    </div>
}

const customizeBtn = (props, s) =>
    <div style={s.customizeBtn}
         onClick={props.onToggle}>
        <img alt=""
             src="/assets/customize.svg"
             style={s.customizeBtn.icon}
             />
        Customize
    </div>

const posterExplanationTip = (isDesktop) => {
    return (
        <ExplanationTip
            isDesktop={isDesktop}
            left={16}
            bottom={89}
            imageWidth={40}
            imageHeight={56}
            imageUrl='/assets/preview-explanation/poster.svg'
            textMarginTop="24px">
            Print it on A4/Letter format and hang up on a wall
        </ExplanationTip>
    )
}

const cardExplanationTip = (isDesktop) => {
    return (
        <ExplanationTip
            isDesktop={isDesktop}
            left={181}
            bottom={89}
            imageWidth={47}
            imageHeight={56}
            imageUrl='/assets/preview-explanation/card.svg'
            textMarginTop="24px">
            Print it on business card format and hand out to your guests
        </ExplanationTip>
    )
}

const virtualExplanationTip = (isDesktop) => {
    const boldTextS = {
        fontFamily: 'TitilliumWeb-Bold',
    }

    return (
        <ExplanationTip
            isDesktop={isDesktop}
            left={337}
            bottom={89}
            imageWidth={32}
            imageHeight={56}
            imageUrl='/assets/preview-explanation/virtual.svg'
            textMarginTop={ isDesktop ? '12px' : '24px' }>
            { isDesktop && <span style={boldTextS}>Only available on mobile. </span> }Get link to your virtual card. Your guests can scan it from your screen.
        </ExplanationTip>
    )
}

const productSelector = (props,
                         s,
                         isDesktop,
                         posterBtnMouseEnter = noop,
                         posterBtnMouseLeave = noop,
                         cardBtnMouseEnter = noop,
                         cardBtnMouseLeave = noop,
                         virtualBtnMouseEnter = noop,
                         virtualBtnMouseLeave = noop) => {
    const poster = {
        value: 'poster',
        label: 'Poster',
        enabled: true,
        onMouseEnter: posterBtnMouseEnter,
        onMouseLeave: posterBtnMouseLeave
    }
    const card = {
        value: 'card',
        label: 'Card',
        enabled: true,
        onMouseEnter: cardBtnMouseEnter,
        onMouseLeave: cardBtnMouseLeave
    }
    const virtual = {
        value: 'virtual',
        label: 'Virtual',
        enabled: !props.isDesktop,
        onMouseEnter: virtualBtnMouseEnter,
        onMouseLeave: virtualBtnMouseLeave
    }

    const buttons = isDesktop
        ? [poster, card, virtual]
        : [virtual, poster, card]
    return <ToggleBtn buttons={buttons}
                      currentValue={props.selectedProduct}
                      onChange={props.onSelectedProductChange}
                      style={s.productSelector}/>
}


const customizationBlock = (props, s) =>
    <div style={s.customizationBlock}>
        { productSelector(props, s, false) }
        { props.selectedProduct === 'poster'
            ? posterExplanationTip(false)
            : props.selectedProduct === 'card'
                ? cardExplanationTip(false)
                : virtualExplanationTip(false)
        }
    </div>

export default BottomSheet