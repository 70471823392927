import React, {useContext} from 'react'
import {trackEvent} from "../analytics/Analytics";
import AppConfig from "../app-config/AppConfig";

const FeedbackFloatingButton = (props) => {
    const config = useContext(AppConfig)

    const onBtnClick = () => {
        trackEvent('feedback-floating-btn-click')
        window.open(config.feedbackUrl, '_blank');
    }

    const buttonS = {
        backgroundColor: '#0048df',
        width: props.isDesktopMode ? '233px' : '132px',
        height: '48px',
        borderRadius: '24px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
        position: 'fixed',
        right: '24px',
        bottom: '24px',
        fontFamily: 'TitilliumWeb-Bold',
        fontSize: '16px',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }

    const iconSize = '16px'
    const iconS = {
        display: 'inline-block',
        width: iconSize,
        height: iconSize,
        backgroundImage: 'url(/assets/external-link.svg)',
        marginRight: '16px'
    }

    const label = props.isDesktopMode ? 'Help us make it better' : 'Help us'
    return (
        <div style={buttonS} onClick={onBtnClick}>
            <div style={iconS}></div>{label}
        </div>
    )
}

export default FeedbackFloatingButton