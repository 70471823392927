import React, {useContext} from 'react';
import Popup from "../../../popup/Popup";
import {trackEvent} from "../../../analytics/Analytics";
import AppConfig from "../../../app-config/AppConfig";
import Emoji from "../../../emoji/Emoji";

export default function FeedbackDialog(props) {
    const mainTitleS = (isDesktop) => {
        return {
            paddingTop: isDesktop ? '64px' : '48px',
            paddingBottom: '24px',
            fontFamily: 'FredokaOne-Regular',
            fontSize: '24px',
            textAlign: 'center',
            color: '#333333'
        }
    }

    const emojiS = {
        height: '30px'
    }

    const containerS = (isDesktop) => {
        return {
            marginTop: isDesktop ? '40px' : '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }

    const orS = {
        fontFamily: 'TitilliumWeb-Regular',
        fontSize: '16px',
        color: '#333333',
        marginTop: '8px',
        marginBottom: '8px'
    }

    const dialogContent = (widthIsMoreThanThreshold, heightIsMoreThanThreshold) => {
        return (<>
            <div style={mainTitleS(widthIsMoreThanThreshold)}>Did you like it? <Emoji type="smiling-face" style={emojiS}/></div>
            <div style={containerS(widthIsMoreThanThreshold)}>
                <BmcBtn/>
                <div style={orS}>or</div>
                <FeedbackBtn/>
            </div>
        </>)
    }

    const onClose = () => {
        trackEvent('feedback-dialog-closed')
        props.onClose()
    }

    return (
        <Popup
            onClose={onClose}
            widthAdaptiveThreshold={460}
            heightAdaptiveThreshold={359}
            content={dialogContent}/>
    )
}

const BmcBtn = () => {
    const imgS = {
        width: '194px',
        borderRadius: '6px',
        cursor: 'pointer'
    }

    const onBtnClick = () => {
        trackEvent('feedback-dialog-bmc-btn-click')
        window.open('https://www.buymeacoffee.com/waflee', '_blank');
    }

    return (
        <img src='/assets/bmc-button.png'
             alt="Buy Me A Coffee"
             style={imgS}
             onClick={onBtnClick}/>
    )
}

const FeedbackBtn = (props) => {
    const config = useContext(AppConfig)

    const buttonS = {
        backgroundColor: '#0048df',
        width: '177px',
        height: '48px',
        borderRadius: '24px',
        fontFamily: 'TitilliumWeb-Bold',
        fontSize: '16px',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: props.isDesktop ? '64px' : '48px'
    }

    const iconSize = '16px'
    const iconS = {
        display: 'inline-block',
        width: iconSize,
        height: iconSize,
        backgroundImage: 'url(/assets/external-link.svg)',
        marginRight: '16px'
    }

    const onBtnClick = () => {
        trackEvent('feedback-dialog-feedback-btn-click')
        window.open(config.feedbackUrl, '_blank');
    }

    return (
        <div style={buttonS} onClick={onBtnClick}>
            <div style={iconS}></div>Leave feedback
        </div>
    )
}