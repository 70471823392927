import React, {useState} from 'react';
import CredForm from './cred-form/CredForm';
import HowItWorksBtn from './how-it-works-btn/HowItWorksBtn';
import HowItWorksDialog from './how-it-works-dialog/HowItWorksDialog';
import DialogContainer from './dialog-container/DialogContainer';
import LayoutPreviewDialog from './layout-preview-dialog/LayoutPreviewDialog';
import { buildQrCode } from './qrcode/wifiQrUtils';
import './App.css';
import PrivacyDialog from "./privacy-dialog/PrivacyDialog";
import {Analytics, trackEvent} from "./analytics/Analytics";
import {useMediaQuery} from "./utils";
import AppConfig from "./app-config/AppConfig";
import FeedbackFloatingButton from "./feedback-floating-btn/FeedbackFloatingButton";

export const App = (props) => {
    const isDesktopMode = useMediaQuery('(min-width: 492px)')
    const [currentDialog, setCurrentDialog] = useState(null)
    const nonbreakingS = {
        whiteSpace: 'nowrap'
    }

    const onHowItWorksClick = () => {
        trackEvent('how-it-works-btn-click')
        setCurrentDialog(<HowItWorksDialog onClose={onDialogClose}/>)
    }
    const onDialogClose = () => setCurrentDialog(null)
    const onCredFormSubmit = (formData) => {
        trackEvent('submit-click')
        setCurrentDialog(createLayoutPreviewDialog(formData))
    }

    const onShowPrivacyBtnClick = () => {
        trackEvent('show-privacy-click')
        setCurrentDialog(<PrivacyDialog onClose={onDialogClose}/>)
    }

    const createLayoutPreviewDialog = (formData) =>
        <LayoutPreviewDialog qrCodeSvgStr={buildQrCode(formData)} 
                             network={formData.network}
                             password={formData.password}
                             protection={formData.protection}
                             onClose={onDialogClose}/>

    return (
        <AppConfig.Provider value={props.config}>
            <div className="content-container">
                <Analytics/>
                <h1 className="main-header main-page-header">Easy way to connect<br/>to your <span style={nonbreakingS}>Wi-Fi</span></h1>
                <div className="main-explanation">Create a special QR code and provide your guests an ability to connect to the <span style={nonbreakingS}>Wi-Fi</span> using their phone camera</div>

                <div className="how-it-works-btn-container">
                    <HowItWorksBtn onClick={onHowItWorksClick}></HowItWorksBtn>
                </div>

                <div className="main-pic-container">
                    <div className="main-pic"></div>
                </div>
                <CredForm onSubmit={onCredFormSubmit}
                          onShowPrivacyBtnClick={onShowPrivacyBtnClick}
                          isDesktopMode={isDesktopMode}/>
                <FeedbackFloatingButton isDesktopMode={isDesktopMode}/>
                <DialogContainer currentDialog={currentDialog}/>
            </div>
        </AppConfig.Provider>
    )
}

export default App
